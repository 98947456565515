#c-user-name-change-requests {
  .feedback-category-positive {
    background: #DDFFDD !important;
  }

  .feedback-category-negative {
    background: #FFDDDD !important;
  }
  
  li {
    margin-bottom: 1em;
  }
  
  form {
    margin-bottom: 2em;
  }
  
  p {
    margin: 0;
  }
  
  section {
    margin-bottom: 1em;
  }
}
