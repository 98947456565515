

form.simple_form {
  margin: 0 0 1em 0;

  div.input.boolean {
    label {
      display: inline;
      vertical-align: middle;
      margin-left: 0.5em;

      input {
        margin-right: 0.25em;
      }
    }
  }

  div.input {
    margin-bottom: 1em;

    input[type=text], input[type=file], input[type=password], input[type=email] {
      max-width: 80%;
    }

    span.hint {
      display: block;
      font-size: 70%;
    }

    textarea {
      width: 80%;
      font-size: 1.2em;
    }

    label {
      display: block;
      font-weight: bold;
      line-height: 1.5em;
    }

    fieldset {
      border: none;
      display: inline;
      margin: 0;
      padding: 0;

      label {
        font-weight: normal;
        width: auto;
        margin-right: 2em;
        display: inline;
      }
    }
  }
}

form.inline-form {
  display: table;

  > div.input {
    display: table-row;
    line-height: 2em;

    label {
      text-align: right;
    }

    label, input {
      display: table-cell;
      padding-right: $base-padding;
      white-space: nowrap;
    }
  }
}

form.one-line-form {
  > input, > div.input {
    display: inline;

    label {
      display: inline;
      margin-right: 1em;
    }
  }
}

div.ui-dialog {
  div.input {
    input[type="text"] {
      width: 100%;
      max-width: 100%;
    }
  }
}
