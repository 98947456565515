

ul.backtrace {
  font-family: monospace;
  font-size: 1.2em;
  list-style-type: none;
  background: $section-background;
  padding: 1em;
  margin-bottom: 1em;
}
