a.pool-category-series, .pool-category-series a {
  color: $pool-series-link-color;

  &:hover {
    color: $pool-series-link-hover-color;
  }
}

a.pool-category-collection, .pool-category-collection a {
  color: $pool-collection-link-color;

  &:hover {
    color: $pool-collection-link-hover-color;
  }
}

div#add-to-pool-dialog {
  font-size: 0.8em;

  form {
    margin-bottom: 1em;
  }

  li {
    margin-left: 1em;
    cursor: pointer;
  }

  .hint {
    display: block;
  }
}

div#c-pools {
  div#a-show {
    #blacklist-box {
      display: inline;
    }
    div#description {
      margin-bottom: 1em;
    }
  }
}

div#c-pool-orders, div#c-pool-import, div#c-favorite-group-orders {
  div#a-edit, div#a-import {
    ul.ui-sortable {
      list-style-type: none;

      li {
        cursor: pointer;
        margin-right: 20px;
        margin-bottom: 20px;
        min-width: 150px;
        min-height: 150px;
        padding: 10px;
        display: inline-block;
      }

      li.ui-state-default {
        background: none;
      }

      li.ui-state-placeholder {
        background: none;
      }
    }
  }
}

ul.inline-pool-list {
  display: inline;

  li {
    display: inline;
  }
}
