

div#c-wiki-pages {
  form span.hint {
    display: block;
  }

  #a-new {
    div.notice {
      font-size: 0.8em;
      padding: 1em;
      margin: 1em 0;
    }
  }
}
