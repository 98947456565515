

div#c-sessions {
  div#a-new {
    label#remember-label {
      display: inline;
      font-weight: normal;
      font-style: italic;
    }
  }
}
