
#mode-box-mode, #mode-box #set-id {
  width: 15em; // Match width to that of the
}

@keyframes heartbeat {
  0% {
    transform:scale(1);
  }
  50% {
    transform:scale(1.3);
  }
  100% {
    transform:scale(1);
  }
}

@keyframes sharpen {
  from {
    filter: blur(8px);
  }

  to {
    filter: none;
  }
}

article.post-preview {
  box-sizing: border-box;
  height: 154px;
  width: 154px;
  margin: 0 10px 10px 0;
  overflow: hidden;
  text-align: center;
  display: inline-block;
  position: relative;

  a {
    display: inline-block;
  }

  &.captioned {
    height: auto;
    vertical-align: text-top;
  }

  .desc {
    @include themable {
      background-color: lighten( themed("color-section"), 5%);
    }
    font-size: 80%;
    margin-bottom: 0;
  }

  .post-score>span {
    font-size: 0.8rem;
    margin-left: 0.5em;
  }

  &.post-rating-explicit .post-score-rating {
    color: $post-rating-explicit-color;
  }

  &.post-rating-safe .post-score-rating {
    color: $post-rating-safe-color;
  }

  &.post-rating-questionable .post-score-rating {
    color: $post-rating-questionable-color;
  }

  img {
    box-sizing: border-box;
    margin: auto;
    max-height: 10rem; // Roughly 160px with 16pt font size
    max-width: 10rem;
    border-radius: $border-radius-half $border-radius-half 0 0;
  }

  &[data-tags~=animated] > a:before {
    @include  preview-type-badge('ANIM');
  }

  &[data-file-ext=webm] > a:before {
    @include preview-type-badge('WEBM');
  }

  //&[data-file-ext=swf]:before {
  //  @include preview-type-badge('FLASH');
  //}
}

#saved-searches-nav {
  margin-top: 1em;
}

#excerpt p.links {
  margin-top: 1em;
}

#edit-dialog textarea {
  margin-bottom: 0.25em;
}

.post-preview {
  &.post-status-has-children img {
    border: 2px solid transparent;
    border-color: $preview-has-children-color;
  }

  &.post-status-has-parent img {
    border: 2px solid transparent;
    border-color: $preview-has-parent-color;
  }

  &.post-status-has-children.post-status-has-parent img {
    border: 2px solid transparent;
    border-color: $preview-has-children-color $preview-has-parent-color $preview-has-parent-color $preview-has-children-color;
  }


  &.post-status-deleted img {
    border: 2px solid transparent;
    border-color: $preview-deleted-color;
  }

  &.post-status-has-children.post-status-deleted img {
    border: 2px solid transparent;
    border-color: $preview-has-children-color $preview-deleted-color $preview-deleted-color $preview-has-children-color;
  }

  &.post-status-has-parent.post-status-deleted img {
    border: 2px solid transparent;
    border-color: $preview-has-parent-color $preview-deleted-color $preview-deleted-color $preview-has-parent-color;
  }

  &.post-status-has-children.post-status-has-parent.post-status-deleted img {
    border: 2px solid transparent;
    border-color: $preview-has-children-color $preview-deleted-color $preview-deleted-color $preview-has-parent-color;
  }


  /* Pending and flagged posts have blue borders (except in the modqueue). */
  &.post-status-pending:not(.mod-queue-preview) img,
  &.post-status-flagged:not(.mod-queue-preview) img {
    border: 2px solid transparent;
    border-color: $preview-pending-color;
  }

  &.post-status-has-children.post-status-pending:not(.mod-queue-preview) img,
  &.post-status-has-children.post-status-flagged:not(.mod-queue-preview) img {
    border: 2px solid transparent;
    border-color: $preview-has-children-color $preview-pending-color $preview-pending-color $preview-has-children-color;
  }

  &.post-status-has-parent.post-status-pending:not(.mod-queue-preview) img,
  &.post-status-has-parent.post-status-flagged:not(.mod-queue-preview) img {
    border: 2px solid transparent;
    border-color: $preview-has-parent-color $preview-pending-color $preview-pending-color $preview-has-parent-color;
  }

  &.post-status-has-children.post-status-has-parent.post-status-pending:not(.mod-queue-preview) img,
  &.post-status-has-children.post-status-has-parent.post-status-flagged:not(.mod-queue-preview) img {
    border: 2px solid transparent;
    border-color: $preview-has-children-color $preview-pending-color $preview-pending-color $preview-has-parent-color;
  }
}

/* Flagged posts have red borders for approvers. */
body[data-user-can-approve-posts="true"] .post-preview {
  &.post-status-flagged img {
    border: 2px solid transparent;
    border-color: $preview-flagged-color;
  }

  &.post-status-has-children.post-status-flagged img {
    border: 2px solid transparent;
    border-color: $preview-has-children-color $preview-flagged-color $preview-flagged-color $preview-has-children-color;
  }

  &.post-status-has-parent.post-status-flagged img {
    border: 2px solid transparent;
    border-color: $preview-has-parent-color $preview-flagged-color $preview-flagged-color $preview-has-parent-color;
  }

  &.post-status-has-children.post-status-has-parent.post-status-flagged img {
    border: 2px solid transparent;
    border-color: $preview-has-children-color $preview-flagged-color $preview-flagged-color $preview-has-parent-color;
  }
}

.post-preview.current-post {
  background-color: $post-preview-highlight-background;
}

#has-parent-relationship-preview, #has-children-relationship-preview {
  overflow-x: auto;
  white-space: nowrap;

  article.post-preview {
    width: auto;
    border: none;
    margin: 0;
    padding: 5px 5px 10px;
  }
}

div#c-posts {
  .source-link {
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    max-width:190px;
  }
  .source-link:hover {
    a {
      @include themable {
        background: themed("color-background");
      }
    }
    overflow:visible;
    height:auto;
    z-index:2;
    position: relative;
    max-width:none;
  }


  .fav-buttons {
    font-size: 14pt;

    i {
      margin-right: 0.1em;
    }

    button.ui-button {
      padding: 0.25em 0.75em;
    }
  }

  .fav-buttons-true #add-fav-button {
    display: none;
  }

  .fav-buttons-false #remove-fav-button {
    display: none;
  }

  #ugoira-controls {
    div#seek-slider {
      margin-top: 0.5em;
      float: right;
      overflow: visible;
      background: $post-ugoira-seek-background;

      .ui-progressbar-value {
        background: $post-ugoira-seek-progress-background;
      }
    }

    #save-video-link {
      margin: 0.5em 0;
    }

    button {
      margin-top: 0.3em;
    }

    #ugoira-load-progress {
      display: inline;
      margin-left: 17px;
    }
  }

  .outer-recent-searches {
    overflow: hidden;
  }

  div.parent-children {
    display: flex;
    margin: 0 -$padding-025;
    margin-bottom: $padding-025;
    div.notice {
      margin: 0 $padding-025;
    }
    div.notice-parent {
      flex-grow: 1;
    }
  }

  div.notice {
    border-radius: $border-radius-half;
    padding: 0.5em;
    margin-bottom: 0.5em;
    overflow: hidden;

    ul {
      margin-left: 1em;
    }

    p {
      margin: 0;
    }

    .resolved {
      margin-left: 0.5em;
      font-weight: bold;
    }

    &.notice-parent {
      @include themable {
        background-color: themed('color-section');
        border: 1px solid themed('color-foreground');
      }
    }

    &.notice-child {
      @include themable {
        background-color: themed('color-section');
        border: 1px solid themed('color-foreground');
      }
    }

    &.notice-pending {
      @include themable {
        background-color: themed('color-section');
        border: 1px solid themed('color-foreground');
      }
    }

    &.notice-no-shortcuts {
      @include themable {
        background-color: darken( themed('color-danger'), 10%);
        border: 1px solid themed("color-foreground");
      }
    }

    &.notice-flagged {
      @include themable {
        background-color: darken( themed('color-danger'), 10%);
        border: 1px solid themed("color-foreground");
      }
    }

    &.notice-deleted {
      @include themable {
        background-color: darken( themed('color-danger'), 10%);
        border: 1px solid themed("color-foreground");
      }
    }

    &.notice-appealed {
      @include themable {
        background-color: themed('color-section');
        border: 1px solid themed('color-foreground');
      }
    }

    &.notice-resized {
      @include themable {
        background-color: themed('color-section');
        border: 1px solid themed('color-foreground');
      }
    }
  }

  div.nav-notice {
    padding: 0.5em;
    margin-bottom: $padding-050;
    @include themable {
      background-color: themed('color-section');
    }
    position: relative;

    p {
      margin: 0;
      text-align: center;
    }
  }

  aside#sidebar #tag-list h2 {
    font-size: $h4_size;
  }

  div#a-index {
    menu#post-sections {
      margin-bottom: 0.5em;
      font-size: $h3-size;

      li {
        padding: 0 1em 0.5em 0;

        &.active {
          font-weight: bold;
        }
      }
    }
  }

  div#a-show {
    .active, .pool-selected-true {
      font-weight: bold;
    }

    .recommended-posts {
      margin-top: 1em;
    }

    menu#post-sections {
      margin: 0;
      font-size: $h3-size;

      li {
        padding: 0 1em 0 0;
      }

      div.share a {
        margin: 0;
      }
    }

    div.notices {
      margin: 1em 0;
    }

    span.quick-mod {
      float: right;
    }

    .pool-name, .search-name, .set-name {
      word-wrap: break-word;
      flex-grow: 2;
      text-align: center;
      max-width: 65%;
      padding: 0 1rem;
    }

    textarea {
      margin-bottom: 0.25em;
    }

    #edit {
      .edit-submit {
        position: sticky;
        bottom: 0;

        @include themable {
          background: themed("color-section");
        }
        width: min-content;
        padding: 1rem 1rem 1rem 0.5rem;
        margin: 0;
      }
      textarea {
        width: 100%;
      }
    }

    #favlist {
      margin-left: 1em;
      word-wrap: break-word;
    }

    .search-seq-nav + .pool-nav, .search-seq-nav + .set-nav, .pool-nav + .set-nav {
      margin-top: 0.5em;
    }

    .pool-nav, .search-seq-nav, .set-nav {
      @include themable {
        background: themed("color-section");
      }
      li {
        display: flex;
        height: 1.25rem;
        align-items: center;
        justify-content: space-between;

        .first, .prev {
          flex-shrink: 2;
        }
        .next, .last {
          flex-shrink: 2;
        }
      }
    }

    span.close-button {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }

    div.comments-for-post {
      width:100%
    }
  }

  div#quick-edit-div {
    textarea {
      width: 70%;
      height: 4em;
      display: block;
    }
  }
}

div#c-post-versions, div#c-artist-versions {
  div#a-index {
    a {
      word-wrap: break-word
    }
  }
}

div#c-explore-posts {
  a.desc {
    font-weight: bold;
    margin: 0 0.5em;
  }

  #popular-nav-links {
    text-align: center;
  }

  .period {
    margin: 0 5em;

    &:first-child {
      margin-left: 0;
    }
  }
}

div#unapprove-dialog {
  p {
    margin-bottom: 1em;
  }
}

textarea[data-autocomplete="tag-edit"] {
  font-family: monospace;
}

#image {
  &.fit-window {
    max-width: 100%;
  }
  &.fit-window-vertical {
    max-width: 100%;
    max-height: 95vh;
  }
}

section#image-extra-controls {
  display: flex;
  align-items: center;
  div {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-right: 0.5em;
  }
}

.flag-dialog-body label {
  white-space: normal;
}

section#tag-list {
  word-break: break-word;
}

#tag-list > ul {
  margin-bottom: $padding-025;
}

.tag-list-header {
  cursor: pointer;
  &.hidden-category::before {
    content: "► ";
  }
}

.image-vote-buttons {
  .post-score {
    padding: 0 $padding-050;
  }
}

div#image-and-nav {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, auto);

  [data-th-nav=bottom] & {
    #nav-links-top {
      display: none;
    }
  }
  [data-th-nav=top] & {
    #nav-links-bottom {
      display: none;
    }
  }
  [data-th-nav=none] & {
    #nav-links-top, #nav-links-bottom {
      display: none;
    }
  }
}

body[data-user-can-approve-posts="true"] .notice {
  &.notice-flagged, &.notice-deleted, &.notice-pending {
    .button {
      font-size: 1.25rem;
      line-height: 1.5rem;
      padding: $padding-025 $padding-050;
    }
  }
}
