div#c-moderator-dashboards {
  div#col1 {
    width: 45%;
    float: left;
    padding-right: 5%;
  }

  div#col2 {
    width: 45%;
    float: left;
  }

  div.activity {
    margin-bottom: 1em;
  }

  div#ip-addr-search {
    margin-bottom: 2em;

    .hint {
      display: block;
    }
  }

  div#activity-search {
    margin-bottom: 2em;
  }

  table {
    width: 100%;
  }

  caption {
    font-size: 1.1em;
    font-weight: bold;
    text-align: left;
  }

  th {
    text-align: left;
  }
}
