

div.related-tags {
  width: 100%;
  margin-bottom: 1em;
  background: $related-tags-background;
  overflow: hidden;
  border-radius: $border-radius-half;
  display: flex;
  flex-wrap: wrap;

  .related-section {
    display: flex;
    flex-direction: row;
    flex: 0 1 10%;
    padding: 5px 10px;

    .related-title {
      padding: 0 5px;
      font-weight: bold;
    }
  }

  .related-items {
    display: flex;
    flex-direction: column;
    margin: 0 -5px;
    padding: 0 5px;

    .related-item {
      border: 1px solid rgba(0, 0, 0, 0.15);
      background: rgba(1, 1, 1, 0.15);
      border-radius: 2px;
      padding: 0 5px;
      max-width: 15rem;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }

  .tag-active {
    background: rgb(0, 111, 250);
    color: white;
  }

  .tag-preview {
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: rgba(1, 1, 1, 0.15);
    border-radius: 2px;
    padding: 3px;
    margin-right: 5px;
    box-sizing: border-box;

    .tag-preview-alias {
      background-color: rgba(150, 0, 0, 0.25);
    }

    .tag-preview-implication {
      background-color: rgba(0, 150, 0, 0.25);
    }
  }
}

@media only screen and (orientation: portrait), (max-width: 1100px) {
  .related-section {
    flex: 0 1 50%;
  }
}
