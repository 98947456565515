

.spoiler {
  color: $spoiler-color;
  background: $spoiler-background;
}

.spoiler a {
  color: $spoiler-link-color;
}

.spoiler:hover {
  color: $spoiler-hover-color;
}

.spoiler:hover a{
  color: $spoiler-link-hover-color;
}
