

div#search {
  margin-bottom: 1em;
}

div#search-box button {
  -webkit-appearance: button;
  border: 1px solid $search-box-border;
  background-color: $search-box-background;
  color: $search-box-color;
  &:hover, &:focus {
    border: 1px solid $search-box-hover-border;
    background-color: $search-box-hover-background;
  }
}

div#page {
  @include themable() {
    background-color: themed("color-foreground");
  }
  @include themable($data_map: $theme_extra_index) {
    background-image: themed("image-foreground");
    background-position: themed("image-foreground-position");
    background-repeat: themed("image-foreground-repeat");
    padding-bottom: themed("content-padding-bottom");
  }
  border-radius: $border-radius-full;

  overflow: visible;
  margin: 0 1rem;
  padding: $padding-050;

  // Fix failure to contain contents in bounding box.
  &:after {
    display: block;
    visibility: hidden;
    content: '';
    clear: both;
  }

  div.site-notice {
    @include themable {
      background-color: themed("color-section");
    }
    padding: $padding-050;
    text-align: center;
    position: relative;
    border: 1px solid $darken-background-50;

    h1 {
      font-size: $h2-size;
    }

    p {
      margin: 0;
    }
  }

  aside#sidebar {
    width: 15em;
    float: left;

    h1 {
      font-size: $h3-size;
    }

    #search-box {
      input[type=text] {
        /* ensure expanded search box renders above post thumbnails. */
        z-index: 1;
        position: relative;
        width: 13em;
        padding: 0.125rem 0.125rem;
        border-right: 0;
        box-sizing: border-box;
        border-radius: $border-radius-half 0 0 $border-radius-half;
      }

      button[type=submit] {
        padding: 2px 6px;
        background: $form-submit-button-background;
        width: 2em;
        border-left: 1px solid #ccc; // HACK: hardcoded color
        border-radius: 0 $border-radius-half $border-radius-half 0;
      }
    }

    #options-box i.fa-bookmark {
      margin-right: 0.25em;
    }
  }

  aside#sidebar > section {
    margin-bottom: 1em;
  }

  section#content {
    overflow: visible;
    margin-left: 15em;
    padding-left: 1em;
  }
}

.mobile-search {
  display: none;
}

footer#page-footer {
  clear: both;
  margin: 1em;
  margin-bottom: 0;
  text-align: center;
  border-radius: $border-radius-full $border-radius-full 0 0;
  padding: $base-padding 0 $base-padding;
  @include themable {
    background-color: themed("color-foreground");
  }
}

div.clearfix {
  clear: both;
}

.search-help {
  float: right;
  font-size: 70%;
}
