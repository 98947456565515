
#page.mode-edit {
  @include themable {
    background-color: $post-mode-edit;
  }
}

#page.mode-tag-script {
  @include themable {
    background-color: $post-mode-tag-script;
  }
}

#page.mode-add-fav {
  @include themable {
    background-color: $post-mode-add-fav;
  }
}

#page.mode-remove-fav {
  @include themable {
    background-color: $post-mode-remove-fav;
  }
}

#page.mode-vote-up {
  @include themable {
    background-color: $post-mode-vote-up;
  }
}

#page.mode-vote-down {
  @include themable {
    background-color: $post-mode-vote-down;
  }
}

#page.mode-lock-rating {
  @include themable {
    background-color: $post-mode-lock-rating;
  }
}

#page.mode-lock-note {
  @include themable {
    background-color: $post-mode-lock-note;
  }
}

#page.mode-approve {
  @include themable {
    background-color: $post-mode-approve;
  }
}

#page.mode-notes {
  @include themable {
    background-color: $post-mode-notes;
  }
}

#page.mode-delete {
  @include themable {
    background-color: $post-mode-delete;
  }
}
