

div#c-iqdb-queries {
  div#a-check {
    article.post-preview {
      height: 180px;
      width: 180px;
      border: $iqdb-post-border solid 1px;

      img {
        margin-top: 10px;
      }
    }

    #filedropzone {
      margin: 1em 0;
    }
  }
}
