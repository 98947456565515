
.user-greeting-outer {
  padding: $padding-025 0 $padding-025;
  span.user-greeting {
    color: $user-home-greeting-color;
    cursor: help;
  }
}

div#c-users {
  div#a-show {
    .profile-container {
      width: 100%;
    }

    .bottom-section {
      display: flex;
    }

    .stats-section {
      display: flex;
    }

    .posts-section {
      .posts {
        display: flex;
      }
    }

    .profile-avatar {
      margin-right: 2em;
    }

    .about-section {
      flex-grow: 2;
      display: flex;
      .about-piece {
        flex-grow: 1;
        margin: 0 1em;
      }
      div {
        flex-basis: 50%;
        overflow-wrap: break-word;
        word-break: break-all;
      }
    }

    div.box {
      h2, h3 {
        color: $user-profile-box-header-color;
      }

      margin-bottom: 2em;
    }

    .vertical-section {
      display: flex;
      flex-direction: column;
    }

    .profile-stats {
      width: 100%;
      border-radius: $border-radius-full;
      @include themable {
        background: rgba(themed("color-section"), 0.8);
      }

      abbr {
        cursor: help;
      }
    }

    .user-statistics {
      width: 100%;
      tr {
        height: 1.75em;
      }

      th {
        width: 10%;
        text-align: right;
        padding-right: 1em;
        vertical-align: top;
      }

      td {
        width: 40%;
        vertical-align: top;
      }

      p {
        margin-bottom: 0.5em;
      }
    }
  }

  div#a-edit {
    h1 {
      margin: 0.5em 0;
    }

    h2 {
      margin: 0.5em 0;
    }

    div.input {
      margin-bottom: 2em;
    }

    div.input span.hint {
      display: block;
      max-width: 70%;
    }

    .active {
      color: black;
    }
  }

  div#a-new {
    max-width: 60em;

    p {
      font-size: 1.2em;
      line-height: 1.4em;
    }
  }
}

@media screen and (max-width: 800px) {
  body.resp {
    div#c-users {
      div#a-show {
        .stats-section {
          flex-direction: column;
        }
        .bottom-section {
          flex-direction: column;
        }
        .vertical-section {
          flex-direction: row;
          flex-wrap: wrap;
        }
        .posts-section {
          order: 2;
          .posts {
            display: block;
          }
        }
        .user-uploads {
          order: 1;
        }
        .about-section {
          flex-direction: column;
        }
        .about-piece {
          margin: 0;
          margin-bottom: $padding-050;
        }
      }
    }
  }
}
