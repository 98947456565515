

div.error-messages {
  margin: 1em 0;
  padding: $base-padding;

  h1 {
    font-size: 1em;
    color: $error-notice-color;
  }
}

div#notice {
  padding: 0.25em;
  position: fixed;
  top: 1rem;
  left: 25%;
  width: 50%;
  z-index: 100;

  @include themable {
    color: themed("color-text");
    background-color: themed("color-success");
    border: 1px solid themed("color-foreground");
  }

  &.ui-state-error {
    @include themable {
      background-color: themed("color-danger");
    }
  }
}

a#close-notice-link {
  position: absolute;
  right: 1em;
}
