

#blacklist-box {
  display: none;
  margin-bottom: 1em;

  .blacklist-help {
    font-size: 0.85em;
    font-weight: bold;
  }

  .blacklist-header {
    display: flex;
    justify-content: space-between;
  }
  #blacklist-collapse {
    cursor: pointer;
    &::before {
      content: '▼ '
    }
    &.hidden::before {
      content: '► ';
    }
  }

  #blacklist-list {
    a {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: bottom;
    }

    a.blacklisted-active {
      text-decoration: line-through;
    }
  }

  &.sidebar-blacklist ul li {
    list-style-type: disc;
    list-style-position: inside;

    a {
      max-width: 75%;
    }
  }

  &.inline-blacklist {
    margin-bottom: 1em;

    #blacklist-list {
      display: inline;

      li {
        display: inline;
        margin-right: 1em;

        a {
          max-width: 25%;
        }
      }
    }
  }
}

.post-preview.blacklisted-active, #c-comments .post.blacklisted-active {
  display: none !important;
}

#image-container.blacklisted-active, .post-thumbnail.blacklisted-active {
  img {
    height: 150px;
    width: 150px;
  }
}

