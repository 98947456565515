

div.list-of-forum-posts {
  article {
    margin: 1em 0em;
    word-wrap: break-word;

    a.voted {
      font-weight: bold;
    }

    span.desc {
      color: grey;
    }

    &:target {
      background-color: $forum-post-highlight-background;
    }

    &[data-is-deleted="true"] {
      background-color: $forum-post-hidden-background;
    }

    .vote-score-up {
      color: $forum-vote-up-color;
    }

    .vote-score-meh {
      color: $forum-vote-meh-color;
    }

    .vote-score-down {
      color: $forum-vote-down-color;
    }

    div.content-menu {
      .edit_forum_post, .edit_forum_topic {
        display: none;
      }

      menu {
        margin-top: 0.5em;

        ul.votes {
          margin: 0.5em 0;
        }

        li {
          margin-right: 1em;
        }
      }
    }
  }
}

div#c-forum-topics {
  span.info {
    color: $forum-topic-info-color;
  }

  span.new {
    font-size: 80%;
    color: $forum-topic-new-color;
    margin-right: 0.5em;
  }

  span.locked-topic {
    color: $forum-topic-locked-color;
  }

  span.level-topic {
    color: $forum-topic-leveled-color;
    font-weight: bold;
  }

  tr.forum-topic-row td:last-child {
    white-space: nowrap;
  }

  tr.forum-topic-row[data-is-deleted="true"] {
    background-color: $forum-topic-hidden-background;
  }
}

#c-forum-posts #a-index {
  tr[data-topic-is-deleted="true"] .forum-post-topic-title::after,
  tr[data-is-deleted="true"] .forum-post-excerpt::after {
    content: " (deleted)";
    color: $forum-topic-except-color;
  }
  tr[data-is-deleted="true"], tr[data-topic-is-deleted="true"] {
    background-color: $forum-topic-hidden-background;
  }
}
