

.ui-autocomplete {
  font-size: 0.9em;

  .ui-state-active {
    border: none;
    margin: 0 -1px;
    background-color: $autocomplete-active-background;
    border-left: 1px solid $autocomplete-border;
    border-right: 1px solid $autocomplete-border;
  }

  .ui-menu-item-wrapper {
    padding: 3px .5em 3px .4em;

    a {
      margin-right: 1em;
    }
  }

  .autocomplete-arrow {
    color: $autocomplete-arrow-color;
  }
}
