

.diff-list {
  .added, .added a {
    color: $diff-added-color;
    text-decoration: none;
    margin-right: 0.5em;
  }

  .added.obsolete, .added.obsolete a {
    color: $diff-added-obsolete-color;
    text-decoration: line-through;
  }

  .removed, .removed a {
    color: $diff-removed-color;
    text-decoration: none;
    margin-right: 0.5em;
  }

  .removed.obsolete, .removed.obsolete a {
    text-decoration: line-through;
    color: $diff-removed-obsolete-color;
  }
}

.diff-list {
  ins, ins a {
    color: $diff-added-color;
    text-decoration: none;
    margin-right: 0.5em;
  }

  del, del a {
    color: $diff-removed-color;
    text-decoration: none;
    margin-right: 0.5em;
  }

  ins.obsolete, ins.obsolete a {
    text-decoration: line-through;
    color: $diff-added-obsolete-color;
  }

  del.obsolete, del.obsolete a {
    text-decoration: line-through;
    color: $diff-removed-obsolete-color;
  }
}
