

#c-bans #a-index {
  tr[data-expired="true"] {
    background-color: $ban-expired-background !important;

    &:hover {
      background-color: $ban-expired-hover-background !important;
    }
  }

  tr[data-expired="false"] {
    background-color: $ban-background !important;

    &:hover {
      background-color: $ban-hover-background !important;
    }
  }
}
