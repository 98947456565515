

#c-edit-history {
  .edit-item {
    display: flex;
    &>div {
      margin-right: 1em;
      display: inline-block;
    }
  }

  ins {
    background-color: $post-version-diff-added-color;
    font-weight: bold;
  }
  del {
    background-color: $post-version-diff-removed-color;
    font-weight: bold;
  }
}
