

div#c-moderator-post-queues {
  div.post {
    margin-bottom: 4em;
    overflow: hidden;

    &.post-pos-score {
      background: $modqueue-post-pos-score-background;
    }

    &.post-neg-score {
      background: $modqueue-post-neg-score-background;
    }

    aside {
      float: left;
      width: 220px;
      text-align: right;
      padding-right: 20px;
    }

    section {
      float: left;
      width: 800px;
    }

    //<% Danbooru.config.modqueue_quality_warning_tags.each do |tag| %>
    //  &[data-tags~="<%= tag %>"] {
    //    background-color: $modqueue-post-quality-warning-background;
    //  }
    //<% end %>
    //
    //<% Danbooru.config.modqueue_sample_warning_tags.each do |tag| %>
    //  &[data-tags~="<%= tag %>"] {
    //    background-color: $modqueue-post-sample-warning-background;
    //  }
    //<% end %>

    &[data-tags~=animated], &[data-file-ext=swf], &[data-file-ext=webm], &[data-file-ext=zip], &[data-file-ext=mp4] {
      aside:before {
        @include animated-icon;
      }
    }

    &[data-has-sound=true] {
      aside:before {
        @include sound-icon;
      }
    }
  }

  div#moderation-guideline {
    width: 900px;

    h1 {
      font-size: $h2-size;
    }
  }
}
