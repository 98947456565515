

.styled-dtext {
  line-height: 1.4em;

  h1, h2, h3 {
    line-height: 1em;
  }

  h1 {
    font-size: $dtext_h1_size;
    padding: $h1_padding;
  }

  h2 {
    font-size: $dtext_h2_size;
    padding: $h2_padding;
  }

  h3 {
    font-size: $dtext_h3_size;
    padding: $h3_padding;
  }

  h4 {
    font-size: $dtext_h4_size;
    padding: $h4_padding;
  }

  h5 {
    font-size: $dtext_h5_size;
    padding: $h4_padding;
  }

  h6 {
    font-size: $dtext_h6_size;
    padding: $h4_padding;
  }

  ul {
    margin-left: 1em;
    margin-bottom: 1em;

    ul {
      margin-bottom: 0;
    }
  }

  li {
    list-style-type: disc;
  }

  pre {
    font-family: monospace;
    font-size: 1.2em;
    margin: 0.5em 0;
    padding: 0.5em 1em;
    white-space: pre-wrap;
  }

  code {
    font-family: monospace;
    white-space: pre-wrap;
    background-color: $dtext-code-background;
    padding: $padding-025;
  }

  .inline-code {
    font-family: monospace;
    white-space: pre-wrap;
    background-color: $dtext-code-background;
    padding: $padding-025;
  }

  div.expandable {
    background: $dtext-expandable-background;
    border-radius: $border-radius-half;

    >div.expandable-header {
      cursor: pointer;
      padding: 0.4em;

      >.section-arrow::after {
        content: '►';
      }

      span {
        margin-right: 0.5em;
        font-weight: bold;
      }
    }

    div.expandable-content {
      display: none;
      padding: 0.4em;

      > :last-child {
        margin-bottom: 0;
      }
    }
  }

  div.expandable.expanded {
    >div.expandable-content {
      display: block;
    }
    >div.expandable-header>.section-arrow::after {
        content: '▼';
    }
  }

  a.dtext-external-link::after {
    // https://fontawesome.com/icons/external-link-alt?style=solid
    @include fa-solid-icon("\f35d");

    font-size: 0.6em;
    padding: 0 0.2em 0 0.3em;
    vertical-align: 1px;
  }

  div.expandable, pre, blockquote {
    padding: $padding-025;
    margin: 0 0 0.5rem;
    border-radius: $border-radius-half;
    @include themable {
      border: 1px solid themed("color-section");
      border-left: 0.25rem solid themed("color-danger");
      background: rgba( lighten( themed("color-section"), 10%), 0.7);
    }
  }

  pre {
    @include themable {
      border-left-color: themed("color-detail-code");
    }
  }

  blockquote {
    @include themable {
      border-left-color: themed("color-detail-quote");
    }
  }

  div.expandable {
    @include themable {
      border-left-color: themed("color-detail-expandable");
    }
  }

  @each $tag-category, $tag-category-name in $tag-categories-short {
    .dtext-color-#{$tag-category} {
      @include themable { color: themed("color-tag-#{$tag-category-name}") };
    }

    .dtext-color-#{$tag-category-name} {
      @include themable { color: themed("color-tag-#{$tag-category-name}") };
    }
  }
}

div.dtext-preview {
  display: none;

  ul {
    margin-left: 1em;
  }
}

div.post-thumbnail.dtext {
  position: relative;
  display: inline-block;
}
