@charset "UTF-8";
html, body {
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, cite, code, del, dfn, em, img, q, s, samp, small, strike, strong, sub, sup, tt, var, dd, dl, dt, li, ol, ul, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  /*  font-weight: normal;*/
  /*  font-style: normal;*/
  font-size: 100%;
  line-height: 1.25em;
  font-family: inherit; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

ol, ul {
  list-style: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: ""; }

html {
  overflow-y: scroll;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

a:focus {
  outline: thin dotted; }

a:hover,
a:active {
  outline: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: middle; }

button,
input {
  line-height: normal;
  *overflow: visible; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input:focus,
textarea:focus,
button:focus {
  outline: none; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

body {
  color: #ffffff;
  background-color: #000000;
  font-family: Verdana, sans-serif;
  font-size: 85%;
  line-height: 1.25em;
  margin: 0 auto; }
  body[data-th-main=pony] {
    color: #ffffff;
    background-color: #250D51; }
  body[data-th-main=bloodlust] {
    color: #ffffff;
    background-color: #000000; }
  body[data-th-main=serpent] {
    color: #000000;
    background-color: #44a544; }
  body[data-th-main=hotdog] {
    color: #000000;
    background-color: #FFFF00; }

abbr[title=required] {
  display: none; }

blockquote {
  background: #284A81;
  border: 1px solid #174891;
  border-radius: 3px;
  margin: 0 0 1rem 0;
  padding: 1rem 1rem 0.2rem; }

code {
  font-family: monospace;
  font-size: 1.2em; }

dd {
  margin-bottom: 1em; }

dt {
  font-weight: bold; }

h1, h2, h3, h4, h5, h6 {
  font-family: Verdana, sans-serif; }

h1 {
  font-size: 2em; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.16667em; }

h4, h5, h6 {
  font-size: 1em; }

fieldset {
  margin-bottom: 1em; }

header {
  margin: 0 0 0.5rem 0;
  padding: 0; }

img {
  border: none;
  vertical-align: middle; }

menu, .menu {
  margin: 0;
  padding: 0; }
  menu ul, .menu ul {
    margin: 0;
    padding: 0; }
  menu li, .menu li {
    margin: 0;
    padding: 0 0.2em;
    list-style-type: none;
    display: inline; }

.menu {
  font-size: 1.16667em; }

p {
  margin-bottom: 1em; }

table tfoot {
  margin-top: 2em; }

.hint {
  color: #AAA;
  font-style: italic;
  max-width: 70%; }

.tn {
  font-size: 0.8em;
  color: gray; }

p.info {
  color: #AAA;
  font-style: italic;
  font-size: 80%; }

.greentext, .greentext a, .approved-ticket {
  color: #3e9e49 !important;
  font-weight: bold; }

.greytext, .greytext a {
  color: #959595 !important;
  font-weight: bold; }

.yellowtext, .yellowtext a, .partial-ticket {
  color: #e4e150 !important;
  font-weight: bold; }

.redtext, .redtext a, .denied-ticket {
  color: #e45f5f !important;
  font-weight: bold; }

.sect_green {
  background-color: #288233;
  /* green */ }

.sect_red {
  background-color: #822828;
  /* red */ }

.sect_yellow {
  background-color: #828028;
  /* yellow */ }

.sect_grey {
  background-color: #5d656e;
  /* yellow */ }

.sect_green a:hover, .sect_red a:hover, .sect_yellow a:hover {
  color: white; }

a,
a:link,
a:visited {
  color: #ffee95;
  text-decoration: none; }
  [data-th-main=pony] a, [data-th-main=pony]
  a:link, [data-th-main=pony]
  a:visited {
    color: #b4c7d9; }
  [data-th-main=bloodlust] a, [data-th-main=bloodlust]
  a:link, [data-th-main=bloodlust]
  a:visited {
    color: #ffee95; }
  [data-th-main=serpent] a, [data-th-main=serpent]
  a:link, [data-th-main=serpent]
  a:visited {
    color: #005500; }
  [data-th-main=hotdog] a, [data-th-main=hotdog]
  a:link, [data-th-main=hotdog]
  a:visited {
    color: #000000; }

a:hover {
  color: #f68b00;
  text-decoration: none; }
  [data-th-main=pony] a:hover {
    color: #e9f2fa; }
  [data-th-main=bloodlust] a:hover {
    color: #f68b00; }
  [data-th-main=serpent] a:hover {
    color: #3A8F3A; }
  [data-th-main=hotdog] a:hover {
    color: #666666; }

a:active {
  color: #ffffff;
  text-decoration: none; }
  [data-th-main=pony] a:active {
    color: #e8c446; }
  [data-th-main=bloodlust] a:active {
    color: #ffffff; }
  [data-th-main=serpent] a:active {
    color: #e8c446; }
  [data-th-main=hotdog] a:active {
    color: #ffe380; }

a.last-page {
  color: #999999; }
  [data-th-main=bloodlust] a.last-page {
    color: #999999; }

a.active {
  font-weight: bold; }

a.wiki-link {
  margin-right: 0.3em; }

.bg-background {
  background-color: #000000; }
  [data-th-main=pony] .bg-background {
    background-color: #250D51; }
  [data-th-main=bloodlust] .bg-background {
    background-color: #000000; }
  [data-th-main=serpent] .bg-background {
    background-color: #44a544; }
  [data-th-main=hotdog] .bg-background {
    background-color: #FFFF00; }

.bg-foreground {
  background-color: #222222; }
  [data-th-main=pony] .bg-foreground {
    background-color: #2f175c; }
  [data-th-main=bloodlust] .bg-foreground {
    background-color: #222222; }
  [data-th-main=serpent] .bg-foreground {
    background-color: #dacf6b; }
  [data-th-main=hotdog] .bg-foreground {
    background-color: #FF0000; }

.bg-section {
  background-color: #333333; }
  [data-th-main=pony] .bg-section {
    background-color: #453269; }
  [data-th-main=bloodlust] .bg-section {
    background-color: #333333; }
  [data-th-main=serpent] .bg-section {
    background-color: #fff48b; }
  [data-th-main=hotdog] .bg-section {
    background-color: #c53c38; }

.bg-highlight {
  background-color: #4d4d4d; }
  [data-th-main=pony] .bg-highlight {
    background-color: #5c428c; }
  [data-th-main=bloodlust] .bg-highlight {
    background-color: #4d4d4d; }
  [data-th-main=serpent] .bg-highlight {
    background-color: #fff9be; }
  [data-th-main=hotdog] .bg-highlight {
    background-color: #d1625f; }

.border-background {
  border: 1px solid #000000; }
  [data-th-main=pony] .border-background {
    border: 1px solid #250D51; }
  [data-th-main=bloodlust] .border-background {
    border: 1px solid #000000; }
  [data-th-main=serpent] .border-background {
    border: 1px solid #44a544; }
  [data-th-main=hotdog] .border-background {
    border: 1px solid #FFFF00; }

.border-foreground {
  border: 1px solid #222222; }
  [data-th-main=pony] .border-foreground {
    border: 1px solid #2f175c; }
  [data-th-main=bloodlust] .border-foreground {
    border: 1px solid #222222; }
  [data-th-main=serpent] .border-foreground {
    border: 1px solid #dacf6b; }
  [data-th-main=hotdog] .border-foreground {
    border: 1px solid #FF0000; }

.border-section {
  border: 1px solid #333333; }
  [data-th-main=pony] .border-section {
    border: 1px solid #453269; }
  [data-th-main=bloodlust] .border-section {
    border: 1px solid #333333; }
  [data-th-main=serpent] .border-section {
    border: 1px solid #fff48b; }
  [data-th-main=hotdog] .border-section {
    border: 1px solid #c53c38; }

.border-highlight {
  border: 1px solid #4d4d4d; }
  [data-th-main=pony] .border-highlight {
    border: 1px solid #5c428c; }
  [data-th-main=bloodlust] .border-highlight {
    border: 1px solid #4d4d4d; }
  [data-th-main=serpent] .border-highlight {
    border: 1px solid #fff9be; }
  [data-th-main=hotdog] .border-highlight {
    border: 1px solid #d1625f; }

.color-text {
  color: #ffffff; }
  [data-th-main=pony] .color-text {
    color: #ffffff; }
  [data-th-main=bloodlust] .color-text {
    color: #ffffff; }
  [data-th-main=serpent] .color-text {
    color: #000000; }
  [data-th-main=hotdog] .color-text {
    color: #000000; }

.color-muted {
  color: #999999; }
  [data-th-main=pony] .color-muted {
    color: #999999; }
  [data-th-main=bloodlust] .color-muted {
    color: #999999; }
  [data-th-main=serpent] .color-muted {
    color: #555555; }
  [data-th-main=hotdog] .color-muted {
    color: #999999; }

.color-white {
  color: #ffffff; }
  [data-th-main=pony] .color-white {
    color: #ffffff; }
  [data-th-main=bloodlust] .color-white {
    color: #ffffff; }
  [data-th-main=serpent] .color-white {
    color: #ffffff; }
  [data-th-main=hotdog] .color-white {
    color: #ffffff; }

.vpad-05 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.button {
  display: inline-block;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.25rem 0.5rem;
  border: 0;
  color: #ffffff; }
  [data-th-main=pony] .button {
    color: #ffffff; }
  [data-th-main=bloodlust] .button {
    color: #ffffff; }
  [data-th-main=serpent] .button {
    color: #ffffff; }
  [data-th-main=hotdog] .button {
    color: #ffffff; }
  .button.btn-danger {
    color: #ffffff;
    background: maroon; }
    .button.btn-danger:hover {
      background: #670000; }
    [data-th-main=pony] .button.btn-danger {
      color: #ffffff;
      background: maroon; }
      [data-th-main=pony] .button.btn-danger:hover {
        background: #670000; }
    [data-th-main=bloodlust] .button.btn-danger {
      color: #ffffff;
      background: maroon; }
      [data-th-main=bloodlust] .button.btn-danger:hover {
        background: #670000; }
    [data-th-main=serpent] .button.btn-danger {
      color: #ffffff;
      background: maroon; }
      [data-th-main=serpent] .button.btn-danger:hover {
        background: #670000; }
    [data-th-main=hotdog] .button.btn-danger {
      color: #ffffff;
      background: maroon; }
      [data-th-main=hotdog] .button.btn-danger:hover {
        background: #670000; }
  .button.btn-warn {
    color: #ffffff;
    background: sienna; }
    .button.btn-warn:hover {
      background: #8c4827; }
    [data-th-main=pony] .button.btn-warn {
      color: #ffffff;
      background: sienna; }
      [data-th-main=pony] .button.btn-warn:hover {
        background: #8c4827; }
    [data-th-main=bloodlust] .button.btn-warn {
      color: #ffffff;
      background: sienna; }
      [data-th-main=bloodlust] .button.btn-warn:hover {
        background: #8c4827; }
    [data-th-main=serpent] .button.btn-warn {
      color: #ffffff;
      background: sienna; }
      [data-th-main=serpent] .button.btn-warn:hover {
        background: #8c4827; }
    [data-th-main=hotdog] .button.btn-warn {
      color: #ffffff;
      background: sienna; }
      [data-th-main=hotdog] .button.btn-warn:hover {
        background: #8c4827; }
  .button.btn-success {
    color: #ffffff;
    background: darkgreen; }
    .button.btn-success:hover {
      background: #004b00; }
    [data-th-main=pony] .button.btn-success {
      color: #ffffff;
      background: darkgreen; }
      [data-th-main=pony] .button.btn-success:hover {
        background: #004b00; }
    [data-th-main=bloodlust] .button.btn-success {
      color: #ffffff;
      background: darkgreen; }
      [data-th-main=bloodlust] .button.btn-success:hover {
        background: #004b00; }
    [data-th-main=serpent] .button.btn-success {
      color: #ffffff;
      background: darkgreen; }
      [data-th-main=serpent] .button.btn-success:hover {
        background: #004b00; }
    [data-th-main=hotdog] .button.btn-success {
      color: #ffffff;
      background: darkgreen; }
      [data-th-main=hotdog] .button.btn-success:hover {
        background: #004b00; }
  .button.btn-neutral {
    color: #ffffff;
    background: #333333; }
    .button.btn-neutral:hover {
      background: #404040; }
    [data-th-main=pony] .button.btn-neutral {
      color: #ffffff;
      background: #453269; }
      [data-th-main=pony] .button.btn-neutral:hover {
        background: #503a7a; }
    [data-th-main=bloodlust] .button.btn-neutral {
      color: #ffffff;
      background: #333333; }
      [data-th-main=bloodlust] .button.btn-neutral:hover {
        background: #404040; }
    [data-th-main=serpent] .button.btn-neutral {
      color: #ffffff;
      background: #fff48b; }
      [data-th-main=serpent] .button.btn-neutral:hover {
        background: #fff6a5; }
    [data-th-main=hotdog] .button.btn-neutral {
      color: #ffffff;
      background: #c53c38; }
      [data-th-main=hotdog] .button.btn-neutral:hover {
        background: #cc4e4b; }
  .button.score-positive {
    color: #3e9e49;
    background: darkgreen; }
    .button.score-positive:hover {
      background: #004b00; }
    [data-th-main=pony] .button.score-positive {
      color: #3e9e49;
      background: darkgreen; }
      [data-th-main=pony] .button.score-positive:hover {
        background: #004b00; }
    [data-th-main=bloodlust] .button.score-positive {
      color: #3e9e49;
      background: darkgreen; }
      [data-th-main=bloodlust] .button.score-positive:hover {
        background: #004b00; }
    [data-th-main=serpent] .button.score-positive {
      color: #3e9e49;
      background: darkgreen; }
      [data-th-main=serpent] .button.score-positive:hover {
        background: #004b00; }
    [data-th-main=hotdog] .button.score-positive {
      color: #3e9e49;
      background: darkgreen; }
      [data-th-main=hotdog] .button.score-positive:hover {
        background: #004b00; }
  .button.score-negative {
    color: #e45f5f;
    background: maroon; }
    .button.score-negative:hover {
      background: #670000; }
    [data-th-main=pony] .button.score-negative {
      color: #e45f5f;
      background: maroon; }
      [data-th-main=pony] .button.score-negative:hover {
        background: #670000; }
    [data-th-main=bloodlust] .button.score-negative {
      color: #e45f5f;
      background: maroon; }
      [data-th-main=bloodlust] .button.score-negative:hover {
        background: #670000; }
    [data-th-main=serpent] .button.score-negative {
      color: #e45f5f;
      background: maroon; }
      [data-th-main=serpent] .button.score-negative:hover {
        background: #670000; }
    [data-th-main=hotdog] .button.score-negative {
      color: #e45f5f;
      background: maroon; }
      [data-th-main=hotdog] .button.score-negative:hover {
        background: #670000; }
  .button.score-neutral {
    color: #999999;
    background: #333333; }
    .button.score-neutral:hover {
      background: #262626; }
    [data-th-main=pony] .button.score-neutral {
      color: #999999;
      background: #453269; }
      [data-th-main=pony] .button.score-neutral:hover {
        background: #3a2a58; }
    [data-th-main=bloodlust] .button.score-neutral {
      color: #999999;
      background: #333333; }
      [data-th-main=bloodlust] .button.score-neutral:hover {
        background: #262626; }
    [data-th-main=serpent] .button.score-neutral {
      color: #555555;
      background: #fff48b; }
      [data-th-main=serpent] .button.score-neutral:hover {
        background: #fff272; }
    [data-th-main=hotdog] .button.score-neutral {
      color: #999999;
      background: #c53c38; }
      [data-th-main=hotdog] .button.score-neutral:hover {
        background: #b13632; }

#ad-leaderboard {
  margin-bottom: 0.5rem;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center; }
  #ad-leaderboard .adzone {
    height: 90px;
    width: 768px; }

.ui-autocomplete {
  font-size: 0.9em; }
  .ui-autocomplete .ui-state-active {
    border: none;
    margin: 0 -1px;
    background-color: #232629;
    border-left: 1px solid #C5C5C5;
    border-right: 1px solid #C5C5C5; }
  .ui-autocomplete .ui-menu-item-wrapper {
    padding: 3px .5em 3px .4em; }
    .ui-autocomplete .ui-menu-item-wrapper a {
      margin-right: 1em; }
  .ui-autocomplete .autocomplete-arrow {
    color: black; }

#blacklist-box {
  display: none;
  margin-bottom: 1em; }
  #blacklist-box .blacklist-help {
    font-size: 0.85em;
    font-weight: bold; }
  #blacklist-box .blacklist-header {
    display: flex;
    justify-content: space-between; }
  #blacklist-box #blacklist-collapse {
    cursor: pointer; }
    #blacklist-box #blacklist-collapse::before {
      content: '▼ '; }
    #blacklist-box #blacklist-collapse.hidden::before {
      content: '► '; }
  #blacklist-box #blacklist-list a {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom; }
  #blacklist-box #blacklist-list a.blacklisted-active {
    text-decoration: line-through; }
  #blacklist-box.sidebar-blacklist ul li {
    list-style-type: disc;
    list-style-position: inside; }
    #blacklist-box.sidebar-blacklist ul li a {
      max-width: 75%; }
  #blacklist-box.inline-blacklist {
    margin-bottom: 1em; }
    #blacklist-box.inline-blacklist #blacklist-list {
      display: inline; }
      #blacklist-box.inline-blacklist #blacklist-list li {
        display: inline;
        margin-right: 1em; }
        #blacklist-box.inline-blacklist #blacklist-list li a {
          max-width: 25%; }

.post-preview.blacklisted-active, #c-comments .post.blacklisted-active {
  display: none !important; }

#image-container.blacklisted-active img, .post-thumbnail.blacklisted-active img {
  height: 150px;
  width: 150px; }

.box-section {
  background-color: #333333;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 3px; }
  [data-th-main=pony] .box-section {
    background-color: #453269; }
  [data-th-main=bloodlust] .box-section {
    background-color: #333333; }
  [data-th-main=serpent] .box-section {
    background-color: #fff48b; }
  [data-th-main=hotdog] .box-section {
    background-color: #c53c38; }

.box-section.sect_green {
  background-color: #288233; }

.box-section.sect_grey {
  background-color: #5d656e; }

.box-section.sect_red {
  background-color: #822828; }

.box-section.sect_yellow {
  background-color: #828028; }

.comment-post-grid {
  background: #333333;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 165px 1fr; }
  [data-th-main=pony] .comment-post-grid {
    background: #453269; }
  [data-th-main=bloodlust] .comment-post-grid {
    background: #333333; }
  [data-th-main=serpent] .comment-post-grid {
    background: #fff48b; }
  [data-th-main=hotdog] .comment-post-grid {
    background: #c53c38; }
  .comment-post-grid .author-info {
    padding: 0.25rem 0.5rem;
    grid-column: 1;
    grid-row: 1;
    background: rgba(255, 255, 255, 0.05); }
    .comment-post-grid .author-info .avatar .post-thumbnail img {
      height: auto;
      width: auto;
      max-height: 140px;
      max-width: 140px; }
    .comment-post-grid .author-info .post-time {
      font-style: italic; }
  .comment-post-grid .content {
    grid-column: 2;
    grid-row: 1;
    padding: 0.25rem 0.5rem; }

@media screen and (max-width: 800px) {
  body.resp .comment-post-grid {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr; }
    body.resp .comment-post-grid .author-info {
      padding: unset;
      grid-column: 1;
      grid-row: 1;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto 1fr;
      grid-column-gap: 0.5rem; }
      body.resp .comment-post-grid .author-info .name-rank {
        grid-column: 2;
        grid-row: 1; }
      body.resp .comment-post-grid .author-info .avatar {
        grid-column: 1;
        grid-row-start: 1;
        grid-row-end: 4; }
        body.resp .comment-post-grid .author-info .avatar .post-thumbnail img {
          height: auto;
          width: auto;
          max-height: 100px;
          max-width: 100px; }
      body.resp .comment-post-grid .author-info .post-time {
        grid-column: 2;
        grid-row: 2; }
    body.resp .comment-post-grid .content {
      grid-column: 1;
      grid-row: 2; } }

.diff-list .added, .diff-list .added a {
  color: green;
  text-decoration: none;
  margin-right: 0.5em; }

.diff-list .added.obsolete, .diff-list .added.obsolete a {
  color: darkGreen;
  text-decoration: line-through; }

.diff-list .removed, .diff-list .removed a {
  color: red;
  text-decoration: none;
  margin-right: 0.5em; }

.diff-list .removed.obsolete, .diff-list .removed.obsolete a {
  text-decoration: line-through;
  color: darkRed; }

.diff-list ins, .diff-list ins a {
  color: green;
  text-decoration: none;
  margin-right: 0.5em; }

.diff-list del, .diff-list del a {
  color: red;
  text-decoration: none;
  margin-right: 0.5em; }

.diff-list ins.obsolete, .diff-list ins.obsolete a {
  text-decoration: line-through;
  color: darkGreen; }

.diff-list del.obsolete, .diff-list del.obsolete a {
  text-decoration: line-through;
  color: darkRed; }

.styled-dtext {
  line-height: 1.4em; }
  .styled-dtext h1, .styled-dtext h2, .styled-dtext h3 {
    line-height: 1em; }
  .styled-dtext h1 {
    font-size: 2em;
    padding: 0.8em 0 0.25em 0; }
  .styled-dtext h2 {
    font-size: 1.8em;
    padding: 0.8em 0 0.25em 0; }
  .styled-dtext h3 {
    font-size: 1.6em;
    padding: 0.8em 0 0.25em 0; }
  .styled-dtext h4 {
    font-size: 1.4em;
    padding: 0.8em 0 0.25em 0; }
  .styled-dtext h5 {
    font-size: 1.2em;
    padding: 0.8em 0 0.25em 0; }
  .styled-dtext h6 {
    font-size: 1em;
    padding: 0.8em 0 0.25em 0; }
  .styled-dtext ul {
    margin-left: 1em;
    margin-bottom: 1em; }
    .styled-dtext ul ul {
      margin-bottom: 0; }
  .styled-dtext li {
    list-style-type: disc; }
  .styled-dtext pre {
    font-family: monospace;
    font-size: 1.2em;
    margin: 0.5em 0;
    padding: 0.5em 1em;
    white-space: pre-wrap; }
  .styled-dtext code {
    font-family: monospace;
    white-space: pre-wrap;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0.25rem; }
  .styled-dtext .inline-code {
    font-family: monospace;
    white-space: pre-wrap;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0.25rem; }
  .styled-dtext div.expandable {
    background: #284a81;
    border-radius: 3px; }
    .styled-dtext div.expandable > div.expandable-header {
      cursor: pointer;
      padding: 0.4em; }
      .styled-dtext div.expandable > div.expandable-header > .section-arrow::after {
        content: '►'; }
      .styled-dtext div.expandable > div.expandable-header span {
        margin-right: 0.5em;
        font-weight: bold; }
    .styled-dtext div.expandable div.expandable-content {
      display: none;
      padding: 0.4em; }
      .styled-dtext div.expandable div.expandable-content > :last-child {
        margin-bottom: 0; }
  .styled-dtext div.expandable.expanded > div.expandable-content {
    display: block; }
  .styled-dtext div.expandable.expanded > div.expandable-header > .section-arrow::after {
    content: '▼'; }
  .styled-dtext a.dtext-external-link::after {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free", "Font Awesome 5 Pro";
    font-weight: 900;
    content: "";
    font-size: 0.6em;
    padding: 0 0.2em 0 0.3em;
    vertical-align: 1px; }
  .styled-dtext div.expandable, .styled-dtext pre, .styled-dtext blockquote {
    padding: 0.25rem;
    margin: 0 0 0.5rem;
    border-radius: 3px;
    border: 1px solid #333333;
    border-left: 0.25rem solid maroon;
    background: rgba(77, 77, 77, 0.7); }
    [data-th-main=pony] .styled-dtext div.expandable, [data-th-main=pony] .styled-dtext pre, [data-th-main=pony] .styled-dtext blockquote {
      border: 1px solid #453269;
      border-left: 0.25rem solid maroon;
      background: rgba(92, 66, 140, 0.7); }
    [data-th-main=bloodlust] .styled-dtext div.expandable, [data-th-main=bloodlust] .styled-dtext pre, [data-th-main=bloodlust] .styled-dtext blockquote {
      border: 1px solid #333333;
      border-left: 0.25rem solid maroon;
      background: rgba(77, 77, 77, 0.7); }
    [data-th-main=serpent] .styled-dtext div.expandable, [data-th-main=serpent] .styled-dtext pre, [data-th-main=serpent] .styled-dtext blockquote {
      border: 1px solid #fff48b;
      border-left: 0.25rem solid maroon;
      background: rgba(255, 249, 190, 0.7); }
    [data-th-main=hotdog] .styled-dtext div.expandable, [data-th-main=hotdog] .styled-dtext pre, [data-th-main=hotdog] .styled-dtext blockquote {
      border: 1px solid #c53c38;
      border-left: 0.25rem solid maroon;
      background: rgba(209, 98, 95, 0.7); }
  .styled-dtext pre {
    border-left-color: #ffe380; }
    [data-th-main=pony] .styled-dtext pre {
      border-left-color: #ffe380; }
    [data-th-main=bloodlust] .styled-dtext pre {
      border-left-color: #ffe380; }
    [data-th-main=serpent] .styled-dtext pre {
      border-left-color: #ffe380; }
    [data-th-main=hotdog] .styled-dtext pre {
      border-left-color: #ffe380; }
  .styled-dtext blockquote {
    border-left-color: #67717b; }
    [data-th-main=pony] .styled-dtext blockquote {
      border-left-color: #67717b; }
    [data-th-main=bloodlust] .styled-dtext blockquote {
      border-left-color: #67717b; }
    [data-th-main=serpent] .styled-dtext blockquote {
      border-left-color: #67717b; }
    [data-th-main=hotdog] .styled-dtext blockquote {
      border-left-color: #67717b; }
  .styled-dtext div.expandable {
    border-left-color: #427982; }
    [data-th-main=pony] .styled-dtext div.expandable {
      border-left-color: #427982; }
    [data-th-main=bloodlust] .styled-dtext div.expandable {
      border-left-color: #427982; }
    [data-th-main=serpent] .styled-dtext div.expandable {
      border-left-color: #427982; }
    [data-th-main=hotdog] .styled-dtext div.expandable {
      border-left-color: #427982; }
  .styled-dtext .dtext-color-gen {
    color: #ffee95; }
    [data-th-main=pony] .styled-dtext .dtext-color-gen {
      color: #e5d6ff; }
    [data-th-main=bloodlust] .styled-dtext .dtext-color-gen {
      color: #ffee95; }
    [data-th-main=serpent] .styled-dtext .dtext-color-gen {
      color: #005500; }
    [data-th-main=hotdog] .styled-dtext .dtext-color-gen {
      color: #000000; }
  .styled-dtext .dtext-color-general {
    color: #ffee95; }
    [data-th-main=pony] .styled-dtext .dtext-color-general {
      color: #e5d6ff; }
    [data-th-main=bloodlust] .styled-dtext .dtext-color-general {
      color: #ffee95; }
    [data-th-main=serpent] .styled-dtext .dtext-color-general {
      color: #005500; }
    [data-th-main=hotdog] .styled-dtext .dtext-color-general {
      color: #000000; }

div.dtext-preview {
  display: none; }
  div.dtext-preview ul {
    margin-left: 1em; }

div.post-thumbnail.dtext {
  position: relative;
  display: inline-block; }

button,
input,
select,
textarea {
  border: 0;
  border-radius: 2px;
  padding: 1px 2px; }

label {
  font-weight: bold;
  white-space: nowrap; }

textarea {
  display: block;
  font-family: monospace;
  font-size: 1.2em; }

textarea.no-block {
  display: inline; }

input[type=button] {
  min-width: 5rem; }

input[type=submit] {
  min-width: 5rem; }

input[type=radio] {
  border-radius: 0;
  box-shadow: none; }

input[type=date] {
  background-color: white; }

input:focus {
  background: #FFC;
  color: black; }

select:focus {
  background: #FFC;
  color: black; }

textarea:focus {
  background: #FFC;
  color: black; }

input:disabled {
  background-color: #DDD; }

.simple_form {
  padding: 0.5rem;
  border-radius: 3px;
  background-color: #333333; }
  [data-th-main=pony] .simple_form {
    background-color: #453269; }
  [data-th-main=bloodlust] .simple_form {
    background-color: #333333; }
  [data-th-main=serpent] .simple_form {
    background-color: #fff48b; }
  [data-th-main=hotdog] .simple_form {
    background-color: #c53c38; }

span.error {
  display: block;
  font-weight: bold;
  color: red; }

span.link {
  color: #b4c7d9;
  cursor: pointer; }

span.count {
  color: #ccc;
  margin-left: 0.5em; }

kbd.key {
  background: #333;
  border: 1px solid #333;
  padding: 1px 6px;
  color: white;
  /*  width: 1em;*/
  text-align: center;
  display: inline-block;
  border-radius: 3px; }

span.inactive {
  color: #aaa; }

td a.last-page {
  color: #666; }

.ui-widget {
  font-family: Verdana, sans-serif; }
  .ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
    font-family: Verdana, sans-serif; }

.ui-widget-content a {
  color: #b4c7d9; }

.ui-widget-content a:hover {
  color: #2e76b4; }

div.ui-dialog {
  height: auto;
  overflow: visible;
  font-size: 1em;
  z-index: 999;
  box-shadow: none;
  padding: 0; }
  div.ui-dialog .ui-dialog-titlebar {
    border-radius: 0;
    border: none; }
  div.ui-dialog ul {
    margin-left: 1em;
    margin-bottom: 1em; }

.ui-state-highlight {
  background-color: #333333;
  color: #ffffff;
  border: 1px solid #061124; }
  [data-th-main=pony] .ui-state-highlight {
    background-color: #453269;
    color: #ffffff; }
  [data-th-main=bloodlust] .ui-state-highlight {
    background-color: #333333;
    color: #ffffff; }
  [data-th-main=serpent] .ui-state-highlight {
    background-color: #fff48b;
    color: #000000; }
  [data-th-main=hotdog] .ui-state-highlight {
    background-color: #c53c38;
    color: #000000; }
  .ui-state-highlight a, .ui-state-highlight a:link, .ui-state-highlight a:active, .ui-state-highlight a:visited {
    color: #ffee95; }
    [data-th-main=pony] .ui-state-highlight a, [data-th-main=pony] .ui-state-highlight a:link, [data-th-main=pony] .ui-state-highlight a:active, [data-th-main=pony] .ui-state-highlight a:visited {
      color: #b4c7d9; }
    [data-th-main=bloodlust] .ui-state-highlight a, [data-th-main=bloodlust] .ui-state-highlight a:link, [data-th-main=bloodlust] .ui-state-highlight a:active, [data-th-main=bloodlust] .ui-state-highlight a:visited {
      color: #ffee95; }
    [data-th-main=serpent] .ui-state-highlight a, [data-th-main=serpent] .ui-state-highlight a:link, [data-th-main=serpent] .ui-state-highlight a:active, [data-th-main=serpent] .ui-state-highlight a:visited {
      color: #005500; }
    [data-th-main=hotdog] .ui-state-highlight a, [data-th-main=hotdog] .ui-state-highlight a:link, [data-th-main=hotdog] .ui-state-highlight a:active, [data-th-main=hotdog] .ui-state-highlight a:visited {
      color: #000000; }
  .ui-state-highlight a:hover {
    color: #f68b00; }
    [data-th-main=pony] .ui-state-highlight a:hover {
      color: #e9f2fa; }
    [data-th-main=bloodlust] .ui-state-highlight a:hover {
      color: #f68b00; }
    [data-th-main=serpent] .ui-state-highlight a:hover {
      color: #3A8F3A; }
    [data-th-main=hotdog] .ui-state-highlight a:hover {
      color: #666666; }

.ui-state-error {
  border: 1px solid #a71c1c;
  background: #e45f5f; }

.ui-button {
  margin: 0;
  padding: 0.25em 1em;
  color: black;
  border: 1px solid silver;
  background-color: white; }
  .ui-button.sub {
    font-size: 90%; }
  .ui-button.tiny {
    font-size: 90%;
    padding: 0.25em 0.5em; }

.ui-button:hover {
  box-shadow: 1px 1px grey;
  background: #e6e6e6; }

.ui-front {
  z-index: 1000; }

.ui-widget-content {
  border: 1px solid #0a1220;
  background-color: #284a81;
  color: #fff; }

.ui-widget-header {
  background-color: #031131;
  color: #fff; }

div#search {
  margin-bottom: 1em; }

div#search-box button {
  -webkit-appearance: button;
  border: 1px solid #b4c7d9;
  background-color: #b4c7d9;
  color: #fff; }
  div#search-box button:hover, div#search-box button:focus {
    border: 1px solid #2e76b4;
    background-color: #2e76b4; }

div#page {
  background-color: #222222;
  background-image: url("images/extras/hex.png");
  background-position: left top;
  background-repeat: repeat-x;
  border-radius: 6px;
  overflow: visible;
  margin: 0 1rem;
  padding: 0.5rem; }
  [data-th-main=pony] div#page {
    background-color: #2f175c; }
  [data-th-main=bloodlust] div#page {
    background-color: #222222; }
  [data-th-main=serpent] div#page {
    background-color: #dacf6b; }
  [data-th-main=hotdog] div#page {
    background-color: #FF0000; }
  [data-th-extra=none] div#page {
    background-image: none;
    background-position: unset;
    background-repeat: unset; }
  [data-th-extra=autumn] div#page {
    background-image: url("images/extras/leaves_bottom.png");
    background-position: left bottom;
    background-repeat: repeat-x;
    padding-bottom: 6rem; }
  [data-th-extra=winter] div#page {
    background-image: url("images/extras/snow_bottom.png"), url("images/extras/snow_top.png");
    background-position: left bottom, left top;
    background-repeat: repeat-x;
    padding-bottom: 3rem; }
  [data-th-extra=spring] div#page {
    background-image: url("images/extras/spring_bottom.png"), url("images/extras/spring_bottom_fox.png"), url("images/extras/spring_top.png"), url("images/extras/spring_top_sky.png");
    background-position: left bottom, right bottom, right top, left top;
    background-repeat: repeat-x, no-repeat, no-repeat, repeat-x;
    padding-bottom: 3rem; }
  [data-th-extra=aurora] div#page {
    background-image: url("images/extras/aurora.png");
    background-position: left top;
    background-repeat: repeat-x; }
  [data-th-extra=space] div#page {
    background-image: url("images/extras/space.png");
    background-position: left top;
    background-repeat: repeat-x; }
  [data-th-extra=stars] div#page {
    background-image: url("images/extras/stars.png");
    background-position: left top;
    background-repeat: repeat-x; }
  div#page:after {
    display: block;
    visibility: hidden;
    content: '';
    clear: both; }
  div#page div.site-notice {
    background-color: #333333;
    padding: 0.5rem;
    text-align: center;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.5); }
    [data-th-main=pony] div#page div.site-notice {
      background-color: #453269; }
    [data-th-main=bloodlust] div#page div.site-notice {
      background-color: #333333; }
    [data-th-main=serpent] div#page div.site-notice {
      background-color: #fff48b; }
    [data-th-main=hotdog] div#page div.site-notice {
      background-color: #c53c38; }
    div#page div.site-notice h1 {
      font-size: 1.5em; }
    div#page div.site-notice p {
      margin: 0; }
  div#page aside#sidebar {
    width: 15em;
    float: left; }
    div#page aside#sidebar h1 {
      font-size: 1.16667em; }
    div#page aside#sidebar #search-box input[type=text] {
      /* ensure expanded search box renders above post thumbnails. */
      z-index: 1;
      position: relative;
      width: 13em;
      padding: 0.125rem 0.125rem;
      border-right: 0;
      box-sizing: border-box;
      border-radius: 3px 0 0 3px; }
    div#page aside#sidebar #search-box button[type=submit] {
      padding: 2px 6px;
      background: #eee;
      width: 2em;
      border-left: 1px solid #ccc;
      border-radius: 0 3px 3px 0; }
    div#page aside#sidebar #options-box i.fa-bookmark {
      margin-right: 0.25em; }
  div#page aside#sidebar > section {
    margin-bottom: 1em; }
  div#page section#content {
    overflow: visible;
    margin-left: 15em;
    padding-left: 1em; }

.mobile-search {
  display: none; }

footer#page-footer {
  clear: both;
  margin: 1em;
  margin-bottom: 0;
  text-align: center;
  border-radius: 6px 6px 0 0;
  padding: 1rem 0 1rem;
  background-color: #222222; }
  [data-th-main=pony] footer#page-footer {
    background-color: #2f175c; }
  [data-th-main=bloodlust] footer#page-footer {
    background-color: #222222; }
  [data-th-main=serpent] footer#page-footer {
    background-color: #dacf6b; }
  [data-th-main=hotdog] footer#page-footer {
    background-color: #FF0000; }

div.clearfix {
  clear: both; }

.search-help {
  float: right;
  font-size: 70%; }

div#news {
  max-height: 1rem;
  overflow-y: hidden;
  user-select: none; }
  div#news:hover {
    cursor: pointer; }
  div#news.open {
    max-height: none; }
  div#news .newsbody {
    margin-top: 5px; }
  div#news .closebutton {
    float: right;
    cursor: pointer;
    margin-right: 10px;
    font-size: 14pt;
    font-weight: bold;
    color: white;
    vertical-align: center;
    margin-top: -3px; }

div#news-updates {
  padding: 5px;
  background: #174692;
  border-bottom: 2px solid #061124;
  overflow: hidden;
  font-size: 0.8em; }
  div#news-updates ul {
    float: left;
    margin: 0;
    padding: 0; }
  div#news-updates li {
    list-style-type: none;
    float: left;
    margin: 0 2em 0 0;
    padding: 0; }
  div#news-updates a#close-news-ticker-link {
    float: right; }

div.error-messages {
  margin: 1em 0;
  padding: 1rem; }
  div.error-messages h1 {
    font-size: 1em;
    color: #a00; }

div#notice {
  padding: 0.25em;
  position: fixed;
  top: 1rem;
  left: 25%;
  width: 50%;
  z-index: 100;
  color: #ffffff;
  background-color: darkgreen;
  border: 1px solid #222222; }
  [data-th-main=pony] div#notice {
    color: #ffffff;
    background-color: darkgreen;
    border: 1px solid #2f175c; }
  [data-th-main=bloodlust] div#notice {
    color: #ffffff;
    background-color: darkgreen;
    border: 1px solid #222222; }
  [data-th-main=serpent] div#notice {
    color: #000000;
    background-color: darkgreen;
    border: 1px solid #dacf6b; }
  [data-th-main=hotdog] div#notice {
    color: #000000;
    background-color: darkgreen;
    border: 1px solid #FF0000; }
  div#notice.ui-state-error {
    background-color: maroon; }
    [data-th-main=pony] div#notice.ui-state-error {
      background-color: maroon; }
    [data-th-main=bloodlust] div#notice.ui-state-error {
      background-color: maroon; }
    [data-th-main=serpent] div#notice.ui-state-error {
      background-color: maroon; }
    [data-th-main=hotdog] div#notice.ui-state-error {
      background-color: maroon; }

a#close-notice-link {
  position: absolute;
  right: 1em; }

#maintoggle {
  display: none; }

header#top {
  font-size: 1.05em;
  margin: 0 1rem 0.8rem;
  color: #ffffff;
  background-color: #000000; }
  [data-th-main=pony] header#top {
    color: #ffffff;
    background-color: #250D51; }
  [data-th-main=bloodlust] header#top {
    color: #ffffff;
    background-color: #000000; }
  [data-th-main=serpent] header#top {
    color: #000000;
    background-color: #44a544; }
  [data-th-main=hotdog] header#top {
    color: #000000;
    background-color: #FFFF00; }
  header#top menu {
    margin-top: -2px;
    background-color: #222222;
    border-radius: 6px;
    padding: 6px 1rem; }
    [data-th-main=pony] header#top menu {
      background-color: #2f175c; }
    [data-th-main=bloodlust] header#top menu {
      background-color: #222222; }
    [data-th-main=serpent] header#top menu {
      background-color: #dacf6b; }
    [data-th-main=hotdog] header#top menu {
      background-color: #FF0000; }
    header#top menu form {
      display: inline-block; }
      header#top menu form input {
        width: 9.5em; }
    header#top menu li {
      margin: 0;
      padding: 0;
      white-space: nowrap; }
    header#top menu li a {
      padding: 6px 10px; }
  header#top menu.main {
    margin-top: 0;
    background-color: #000000; }
    [data-th-main=pony] header#top menu.main {
      background-color: #250D51; }
    [data-th-main=bloodlust] header#top menu.main {
      background-color: #000000; }
    [data-th-main=serpent] header#top menu.main {
      background-color: #44a544; }
    [data-th-main=hotdog] header#top menu.main {
      background-color: #FFFF00; }
    header#top menu.main li.current a {
      background-color: #222222;
      font-weight: bold; }
      [data-th-main=pony] header#top menu.main li.current a {
        background-color: #2f175c; }
      [data-th-main=bloodlust] header#top menu.main li.current a {
        background-color: #222222; }
      [data-th-main=serpent] header#top menu.main li.current a {
        background-color: #dacf6b; }
      [data-th-main=hotdog] header#top menu.main li.current a {
        background-color: #FF0000; }
    header#top menu.main li#nav-sign-in a {
      font-weight: bold;
      color: #e00; }
    header#top menu.main li.forum-updated a {
      font-style: italic; }
  header#top menu:only-child {
    border-bottom: 2px solid #152F56; }

div.paginator {
  display: block;
  padding: 2em 0 1em 0;
  text-align: center;
  clear: both; }
  div.paginator li a {
    margin: 0 0.25em;
    padding: 0.25em 0.75em; }
  div.paginator li.more {
    color: grey; }
  div.paginator li a.arrow:hover {
    background: white;
    color: #2e76b4; }
  div.paginator li a:hover {
    background: #2e76b4;
    color: white; }
  div.paginator li span {
    margin: 0 0.25em;
    padding: 0.25em 0.75em;
    font-weight: bold; }

.score-negative,
.score-neutral,
.score-positive,
.post-score-rating {
  font-weight: 700; }

.score-positive, span.score-positive {
  color: #3e9e49; }
  .score-positive a, span.score-positive a {
    color: #3e9e49; }
  .score-positive a:hover, span.score-positive a:hover {
    color: #307938; }
  [data-th-main=pony] .score-positive, [data-th-main=pony] span.score-positive {
    color: #3e9e49; }
    [data-th-main=pony] .score-positive a, [data-th-main=pony] span.score-positive a {
      color: #3e9e49; }
    [data-th-main=pony] .score-positive a:hover, [data-th-main=pony] span.score-positive a:hover {
      color: #307938; }
  [data-th-main=bloodlust] .score-positive, [data-th-main=bloodlust] span.score-positive {
    color: #3e9e49; }
    [data-th-main=bloodlust] .score-positive a, [data-th-main=bloodlust] span.score-positive a {
      color: #3e9e49; }
    [data-th-main=bloodlust] .score-positive a:hover, [data-th-main=bloodlust] span.score-positive a:hover {
      color: #307938; }
  [data-th-main=serpent] .score-positive, [data-th-main=serpent] span.score-positive {
    color: #3e9e49; }
    [data-th-main=serpent] .score-positive a, [data-th-main=serpent] span.score-positive a {
      color: #3e9e49; }
    [data-th-main=serpent] .score-positive a:hover, [data-th-main=serpent] span.score-positive a:hover {
      color: #307938; }
  [data-th-main=hotdog] .score-positive, [data-th-main=hotdog] span.score-positive {
    color: #3e9e49; }
    [data-th-main=hotdog] .score-positive a, [data-th-main=hotdog] span.score-positive a {
      color: #3e9e49; }
    [data-th-main=hotdog] .score-positive a:hover, [data-th-main=hotdog] span.score-positive a:hover {
      color: #307938; }

.score-negative, span.score-negative {
  color: #e45f5f; }
  .score-negative a, span.score-negative a {
    color: #e45f5f; }
  .score-negative a:hover, span.score-negative a:hover {
    color: #dd3333; }
  [data-th-main=pony] .score-negative, [data-th-main=pony] span.score-negative {
    color: #e45f5f; }
    [data-th-main=pony] .score-negative a, [data-th-main=pony] span.score-negative a {
      color: #e45f5f; }
    [data-th-main=pony] .score-negative a:hover, [data-th-main=pony] span.score-negative a:hover {
      color: #dd3333; }
  [data-th-main=bloodlust] .score-negative, [data-th-main=bloodlust] span.score-negative {
    color: #e45f5f; }
    [data-th-main=bloodlust] .score-negative a, [data-th-main=bloodlust] span.score-negative a {
      color: #e45f5f; }
    [data-th-main=bloodlust] .score-negative a:hover, [data-th-main=bloodlust] span.score-negative a:hover {
      color: #dd3333; }
  [data-th-main=serpent] .score-negative, [data-th-main=serpent] span.score-negative {
    color: #e45f5f; }
    [data-th-main=serpent] .score-negative a, [data-th-main=serpent] span.score-negative a {
      color: #e45f5f; }
    [data-th-main=serpent] .score-negative a:hover, [data-th-main=serpent] span.score-negative a:hover {
      color: #dd3333; }
  [data-th-main=hotdog] .score-negative, [data-th-main=hotdog] span.score-negative {
    color: #e45f5f; }
    [data-th-main=hotdog] .score-negative a, [data-th-main=hotdog] span.score-negative a {
      color: #e45f5f; }
    [data-th-main=hotdog] .score-negative a:hover, [data-th-main=hotdog] span.score-negative a:hover {
      color: #dd3333; }

.score-neutral, span.score-neutral {
  color: #999999; }
  .score-neutral a:hover, span.score-neutral a:hover {
    color: gray; }
  [data-th-main=pony] .score-neutral, [data-th-main=pony] span.score-neutral {
    color: #999999; }
    [data-th-main=pony] .score-neutral a:hover, [data-th-main=pony] span.score-neutral a:hover {
      color: gray; }
  [data-th-main=bloodlust] .score-neutral, [data-th-main=bloodlust] span.score-neutral {
    color: #999999; }
    [data-th-main=bloodlust] .score-neutral a:hover, [data-th-main=bloodlust] span.score-neutral a:hover {
      color: gray; }
  [data-th-main=serpent] .score-neutral, [data-th-main=serpent] span.score-neutral {
    color: #555555; }
    [data-th-main=serpent] .score-neutral a:hover, [data-th-main=serpent] span.score-neutral a:hover {
      color: #3c3c3c; }
  [data-th-main=hotdog] .score-neutral, [data-th-main=hotdog] span.score-neutral {
    color: #999999; }
    [data-th-main=hotdog] .score-neutral a:hover, [data-th-main=hotdog] span.score-neutral a:hover {
      color: gray; }

form.simple_form {
  margin: 0 0 1em 0; }
  form.simple_form div.input.boolean label {
    display: inline;
    vertical-align: middle;
    margin-left: 0.5em; }
    form.simple_form div.input.boolean label input {
      margin-right: 0.25em; }
  form.simple_form div.input {
    margin-bottom: 1em; }
    form.simple_form div.input input[type=text], form.simple_form div.input input[type=file], form.simple_form div.input input[type=password], form.simple_form div.input input[type=email] {
      max-width: 80%; }
    form.simple_form div.input span.hint {
      display: block;
      font-size: 70%; }
    form.simple_form div.input textarea {
      width: 80%;
      font-size: 1.2em; }
    form.simple_form div.input label {
      display: block;
      font-weight: bold;
      line-height: 1.5em; }
    form.simple_form div.input fieldset {
      border: none;
      display: inline;
      margin: 0;
      padding: 0; }
      form.simple_form div.input fieldset label {
        font-weight: normal;
        width: auto;
        margin-right: 2em;
        display: inline; }

form.inline-form {
  display: table; }
  form.inline-form > div.input {
    display: table-row;
    line-height: 2em; }
    form.inline-form > div.input label {
      text-align: right; }
    form.inline-form > div.input label, form.inline-form > div.input input {
      display: table-cell;
      padding-right: 1rem;
      white-space: nowrap; }

form.one-line-form > input, form.one-line-form > div.input {
  display: inline; }
  form.one-line-form > input label, form.one-line-form > div.input label {
    display: inline;
    margin-right: 1em; }

div.ui-dialog div.input input[type="text"] {
  width: 100%;
  max-width: 100%; }

.spoiler {
  color: black;
  background: black; }

.spoiler a {
  color: black; }

.spoiler:hover {
  color: white; }

.spoiler:hover a {
  color: white; }

table.table, table.striped {
  width: 100%; }
  table.table p, table.striped p {
    margin: 0; }
  table.table td, table.striped td, table.table th, table.striped th {
    padding: 4px 6px; }
  table.table tbody tr, table.striped tbody tr {
    background-color: #333333;
    border-bottom: 1px solid #222222;
    border-bottom: 1px solid #255193; }
    table.table tbody tr td, table.striped tbody tr td {
      padding: 0.5rem 0.25rem; }
    [data-th-main=pony] table.table tbody tr, [data-th-main=pony] table.striped tbody tr {
      background-color: #453269;
      border-bottom: 1px solid #2f175c; }
    [data-th-main=bloodlust] table.table tbody tr, [data-th-main=bloodlust] table.striped tbody tr {
      background-color: #333333;
      border-bottom: 1px solid #222222; }
    [data-th-main=serpent] table.table tbody tr, [data-th-main=serpent] table.striped tbody tr {
      background-color: #fff48b;
      border-bottom: 1px solid #dacf6b; }
    [data-th-main=hotdog] table.table tbody tr, [data-th-main=hotdog] table.striped tbody tr {
      background-color: #c53c38;
      border-bottom: 1px solid #FF0000; }
    table.table tbody tr:hover, table.striped tbody tr:hover {
      background-color: #4d4d4d; }
      [data-th-main=pony] table.table tbody tr:hover, [data-th-main=pony] table.striped tbody tr:hover {
        background-color: #5c428c; }
      [data-th-main=bloodlust] table.table tbody tr:hover, [data-th-main=bloodlust] table.striped tbody tr:hover {
        background-color: #4d4d4d; }
      [data-th-main=serpent] table.table tbody tr:hover, [data-th-main=serpent] table.striped tbody tr:hover {
        background-color: #fff9be; }
      [data-th-main=hotdog] table.table tbody tr:hover, [data-th-main=hotdog] table.striped tbody tr:hover {
        background-color: #d1625f; }
    table.table tbody tr.selected, table.striped tbody tr.selected {
      background-color: #4d4d4d; }
      [data-th-main=pony] table.table tbody tr.selected, [data-th-main=pony] table.striped tbody tr.selected {
        background-color: #5c428c; }
      [data-th-main=bloodlust] table.table tbody tr.selected, [data-th-main=bloodlust] table.striped tbody tr.selected {
        background-color: #4d4d4d; }
      [data-th-main=serpent] table.table tbody tr.selected, [data-th-main=serpent] table.striped tbody tr.selected {
        background-color: #fff9be; }
      [data-th-main=hotdog] table.table tbody tr.selected, [data-th-main=hotdog] table.striped tbody tr.selected {
        background-color: #d1625f; }
  table.table .number, table.striped .number, table.table .links, table.striped .links {
    text-align: right; }
  table.table thead tr, table.striped thead tr {
    background-color: #262626;
    border-bottom: 1px solid #222222; }
    [data-th-main=pony] table.table thead tr, [data-th-main=pony] table.striped thead tr {
      background-color: #3a2a58;
      border-bottom: 1px solid #2f175c; }
    [data-th-main=bloodlust] table.table thead tr, [data-th-main=bloodlust] table.striped thead tr {
      background-color: #262626;
      border-bottom: 1px solid #222222; }
    [data-th-main=serpent] table.table thead tr, [data-th-main=serpent] table.striped thead tr {
      background-color: #fff272;
      border-bottom: 1px solid #dacf6b; }
    [data-th-main=hotdog] table.table thead tr, [data-th-main=hotdog] table.striped thead tr {
      background-color: #b13632;
      border-bottom: 1px solid #FF0000; }
  table.table thead th, table.striped thead th {
    font-weight: bold;
    text-align: left;
    color: #e6e6e6; }
    [data-th-main=pony] table.table thead th, [data-th-main=pony] table.striped thead th {
      color: #e6e6e6; }
    [data-th-main=bloodlust] table.table thead th, [data-th-main=bloodlust] table.striped thead th {
      color: #e6e6e6; }
    [data-th-main=serpent] table.table thead th, [data-th-main=serpent] table.striped thead th {
      color: black; }
    [data-th-main=hotdog] table.table thead th, [data-th-main=hotdog] table.striped thead th {
      color: black; }

/*
 * A table where one column expands to fill the screen, while the
 * other columns shrink to fit their contents.
 */
table.autofit td, table.autofit th, table.autofit .col-fit {
  white-space: nowrap;
  padding-right: 2em; }

table.autofit .col-expand {
  white-space: normal;
  width: 100%; }

table.autofit .col-normal {
  white-space: normal; }

table.search tr, table.aligned-vertical tr {
  height: 2em; }

table.search th, table.aligned-vertical th {
  text-align: right;
  padding-right: 1rem;
  vertical-align: top; }

table.search td, table.aligned-vertical td {
  vertical-align: top; }

table.aligned-vertical tr {
  height: 1.75em; }

.category-0 a, .category-0 a:link, .category-0 a:visited,
a.tag-type-0,
a.tag-type-0:link,
a.tag-type-0:visited,
.ui-widget-content .category-0 a,
.ui-widget-content .category-0 a:link,
.ui-widget-content .category-0 a:visited,
.ui-widget-content a.tag-type-0,
.ui-widget-content a.tag-type-0:link,
.ui-widget-content a.tag-type-0:visited {
  color: #ffee95; }
  [data-th-main=pony] .category-0 a, [data-th-main=pony] .category-0 a:link, [data-th-main=pony] .category-0 a:visited, [data-th-main=pony]
  a.tag-type-0, [data-th-main=pony]
  a.tag-type-0:link, [data-th-main=pony]
  a.tag-type-0:visited, [data-th-main=pony]
  .ui-widget-content .category-0 a, [data-th-main=pony]
  .ui-widget-content .category-0 a:link, [data-th-main=pony]
  .ui-widget-content .category-0 a:visited, [data-th-main=pony]
  .ui-widget-content a.tag-type-0, [data-th-main=pony]
  .ui-widget-content a.tag-type-0:link, [data-th-main=pony]
  .ui-widget-content a.tag-type-0:visited {
    color: #e5d6ff; }
  [data-th-main=bloodlust] .category-0 a, [data-th-main=bloodlust] .category-0 a:link, [data-th-main=bloodlust] .category-0 a:visited, [data-th-main=bloodlust]
  a.tag-type-0, [data-th-main=bloodlust]
  a.tag-type-0:link, [data-th-main=bloodlust]
  a.tag-type-0:visited, [data-th-main=bloodlust]
  .ui-widget-content .category-0 a, [data-th-main=bloodlust]
  .ui-widget-content .category-0 a:link, [data-th-main=bloodlust]
  .ui-widget-content .category-0 a:visited, [data-th-main=bloodlust]
  .ui-widget-content a.tag-type-0, [data-th-main=bloodlust]
  .ui-widget-content a.tag-type-0:link, [data-th-main=bloodlust]
  .ui-widget-content a.tag-type-0:visited {
    color: #ffee95; }
  [data-th-main=serpent] .category-0 a, [data-th-main=serpent] .category-0 a:link, [data-th-main=serpent] .category-0 a:visited, [data-th-main=serpent]
  a.tag-type-0, [data-th-main=serpent]
  a.tag-type-0:link, [data-th-main=serpent]
  a.tag-type-0:visited, [data-th-main=serpent]
  .ui-widget-content .category-0 a, [data-th-main=serpent]
  .ui-widget-content .category-0 a:link, [data-th-main=serpent]
  .ui-widget-content .category-0 a:visited, [data-th-main=serpent]
  .ui-widget-content a.tag-type-0, [data-th-main=serpent]
  .ui-widget-content a.tag-type-0:link, [data-th-main=serpent]
  .ui-widget-content a.tag-type-0:visited {
    color: #005500; }
  [data-th-main=hotdog] .category-0 a, [data-th-main=hotdog] .category-0 a:link, [data-th-main=hotdog] .category-0 a:visited, [data-th-main=hotdog]
  a.tag-type-0, [data-th-main=hotdog]
  a.tag-type-0:link, [data-th-main=hotdog]
  a.tag-type-0:visited, [data-th-main=hotdog]
  .ui-widget-content .category-0 a, [data-th-main=hotdog]
  .ui-widget-content .category-0 a:link, [data-th-main=hotdog]
  .ui-widget-content .category-0 a:visited, [data-th-main=hotdog]
  .ui-widget-content a.tag-type-0, [data-th-main=hotdog]
  .ui-widget-content a.tag-type-0:link, [data-th-main=hotdog]
  .ui-widget-content a.tag-type-0:visited {
    color: #000000; }

.category-0 a:hover,
a.tag-type-0:hover,
.ui-widget-content .category-0 a:hover,
.ui-widget-content a.tag-type-0:hover {
  color: #f68b00; }
  [data-th-main=pony] .category-0 a:hover, [data-th-main=pony]
  a.tag-type-0:hover, [data-th-main=pony]
  .ui-widget-content .category-0 a:hover, [data-th-main=pony]
  .ui-widget-content a.tag-type-0:hover {
    color: #2e76b4; }
  [data-th-main=bloodlust] .category-0 a:hover, [data-th-main=bloodlust]
  a.tag-type-0:hover, [data-th-main=bloodlust]
  .ui-widget-content .category-0 a:hover, [data-th-main=bloodlust]
  .ui-widget-content a.tag-type-0:hover {
    color: #f68b00; }
  [data-th-main=serpent] .category-0 a:hover, [data-th-main=serpent]
  a.tag-type-0:hover, [data-th-main=serpent]
  .ui-widget-content .category-0 a:hover, [data-th-main=serpent]
  .ui-widget-content a.tag-type-0:hover {
    color: #3A8F3A; }
  [data-th-main=hotdog] .category-0 a:hover, [data-th-main=hotdog]
  a.tag-type-0:hover, [data-th-main=hotdog]
  .ui-widget-content .category-0 a:hover, [data-th-main=hotdog]
  .ui-widget-content a.tag-type-0:hover {
    color: #999999; }

.category-0 a.selected,
a.tag-type-0.selected,
.ui-widget-content .category-0 a.selected,
.ui-widget-content a.tag-type-0.selected {
  color: white; }

.ui-widget-content .ui-state-active a.tag-type-0 {
  color: #f68b00; }
  [data-th-main=pony] .ui-widget-content .ui-state-active a.tag-type-0 {
    color: #2e76b4; }
  [data-th-main=bloodlust] .ui-widget-content .ui-state-active a.tag-type-0 {
    color: #f68b00; }
  [data-th-main=serpent] .ui-widget-content .ui-state-active a.tag-type-0 {
    color: #3A8F3A; }
  [data-th-main=hotdog] .ui-widget-content .ui-state-active a.tag-type-0 {
    color: #999999; }
  .ui-widget-content .ui-state-active a.tag-type-0:link, .ui-widget-content .ui-state-active a.tag-type-0:visited {
    color: #ffee95; }
    [data-th-main=pony] .ui-widget-content .ui-state-active a.tag-type-0:link, [data-th-main=pony] .ui-widget-content .ui-state-active a.tag-type-0:visited {
      color: #e5d6ff; }
    [data-th-main=bloodlust] .ui-widget-content .ui-state-active a.tag-type-0:link, [data-th-main=bloodlust] .ui-widget-content .ui-state-active a.tag-type-0:visited {
      color: #ffee95; }
    [data-th-main=serpent] .ui-widget-content .ui-state-active a.tag-type-0:link, [data-th-main=serpent] .ui-widget-content .ui-state-active a.tag-type-0:visited {
      color: #005500; }
    [data-th-main=hotdog] .ui-widget-content .ui-state-active a.tag-type-0:link, [data-th-main=hotdog] .ui-widget-content .ui-state-active a.tag-type-0:visited {
      color: #000000; }
  .ui-widget-content .ui-state-active a.tag-type-0:hover {
    color: #f68b00; }
    [data-th-main=pony] .ui-widget-content .ui-state-active a.tag-type-0:hover {
      color: #2e76b4; }
    [data-th-main=bloodlust] .ui-widget-content .ui-state-active a.tag-type-0:hover {
      color: #f68b00; }
    [data-th-main=serpent] .ui-widget-content .ui-state-active a.tag-type-0:hover {
      color: #3A8F3A; }
    [data-th-main=hotdog] .ui-widget-content .ui-state-active a.tag-type-0:hover {
      color: #999999; }
  .ui-widget-content .ui-state-active a.tag-type-0.selected {
    color: white; }

.category-1 a.selected,
a.tag-type-1.selected,
.ui-widget-content .category-1 a.selected,
.ui-widget-content a.tag-type-1.selected {
  color: white; }

.ui-widget-content .ui-state-active a.tag-type-1.selected {
  color: white; }

.category-3 a.selected,
a.tag-type-3.selected,
.ui-widget-content .category-3 a.selected,
.ui-widget-content a.tag-type-3.selected {
  color: white; }

.ui-widget-content .ui-state-active a.tag-type-3.selected {
  color: white; }

.category-4 a.selected,
a.tag-type-4.selected,
.ui-widget-content .category-4 a.selected,
.ui-widget-content a.tag-type-4.selected {
  color: white; }

.ui-widget-content .ui-state-active a.tag-type-4.selected {
  color: white; }

.category-5 a.selected,
a.tag-type-5.selected,
.ui-widget-content .category-5 a.selected,
.ui-widget-content a.tag-type-5.selected {
  color: white; }

.ui-widget-content .ui-state-active a.tag-type-5.selected {
  color: white; }

.category-6 a.selected,
a.tag-type-6.selected,
.ui-widget-content .category-6 a.selected,
.ui-widget-content a.tag-type-6.selected {
  color: white; }

.ui-widget-content .ui-state-active a.tag-type-6.selected {
  color: white; }

.category-7 a.selected,
a.tag-type-7.selected,
.ui-widget-content .category-7 a.selected,
.ui-widget-content a.tag-type-7.selected {
  color: white; }

.ui-widget-content .ui-state-active a.tag-type-7.selected {
  color: white; }

.category-8 a.selected,
a.tag-type-8.selected,
.ui-widget-content .category-8 a.selected,
.ui-widget-content a.tag-type-8.selected {
  color: white; }

.ui-widget-content .ui-state-active a.tag-type-8.selected {
  color: white; }

.category-banned a, a.tag-type-banned, .ui-state-active a.tag-type-banned {
  color: black;
  background-color: red; }

.inline-tag-list ul {
  display: inline; }
  .inline-tag-list ul li {
    display: inline;
    margin-right: 0.5em; }

.post-count {
  font-size: 85%;
  font-weight: 500;
  margin-left: 0.2em; }

.low-post-count {
  color: red; }

a.user-member.with-style {
  color: #ffee95; }
  [data-th-main=pony] a.user-member.with-style {
    color: #e5d6ff; }
  [data-th-main=bloodlust] a.user-member.with-style {
    color: #ffee95; }
  [data-th-main=serpent] a.user-member.with-style {
    color: #005500; }
  [data-th-main=hotdog] a.user-member.with-style {
    color: #000000; }

a.user-member.with-style:hover {
  color: #f68b00; }
  [data-th-main=pony] a.user-member.with-style:hover {
    color: #2e76b4; }
  [data-th-main=bloodlust] a.user-member.with-style:hover {
    color: #f68b00; }
  [data-th-main=serpent] a.user-member.with-style:hover {
    color: #3A8F3A; }
  [data-th-main=hotdog] a.user-member.with-style:hover {
    color: #999999; }

a.user-privileged.with-style {
  color: #ffee95; }
  [data-th-main=pony] a.user-privileged.with-style {
    color: #e5d6ff; }
  [data-th-main=bloodlust] a.user-privileged.with-style {
    color: #ffee95; }
  [data-th-main=serpent] a.user-privileged.with-style {
    color: #005500; }
  [data-th-main=hotdog] a.user-privileged.with-style {
    color: #000000; }

a.user-privileged.with-style:hover {
  color: #f68b00; }
  [data-th-main=pony] a.user-privileged.with-style:hover {
    color: #2e76b4; }
  [data-th-main=bloodlust] a.user-privileged.with-style:hover {
    color: #f68b00; }
  [data-th-main=serpent] a.user-privileged.with-style:hover {
    color: #3A8F3A; }
  [data-th-main=hotdog] a.user-privileged.with-style:hover {
    color: #999999; }

a.user-contributor.with-style {
  color: #ffee95; }
  [data-th-main=pony] a.user-contributor.with-style {
    color: #e5d6ff; }
  [data-th-main=bloodlust] a.user-contributor.with-style {
    color: #ffee95; }
  [data-th-main=serpent] a.user-contributor.with-style {
    color: #005500; }
  [data-th-main=hotdog] a.user-contributor.with-style {
    color: #000000; }

a.user-contributor.with-style:hover {
  color: #f68b00; }
  [data-th-main=pony] a.user-contributor.with-style:hover {
    color: #2e76b4; }
  [data-th-main=bloodlust] a.user-contributor.with-style:hover {
    color: #f68b00; }
  [data-th-main=serpent] a.user-contributor.with-style:hover {
    color: #3A8F3A; }
  [data-th-main=hotdog] a.user-contributor.with-style:hover {
    color: #999999; }

a.user-blocked.with-style {
  color: #ffee95;
  text-decoration: line-through; }
  [data-th-main=pony] a.user-blocked.with-style {
    color: #e5d6ff;
    text-decoration: line-through; }
  [data-th-main=bloodlust] a.user-blocked.with-style {
    color: #ffee95;
    text-decoration: line-through; }
  [data-th-main=serpent] a.user-blocked.with-style {
    color: #005500;
    text-decoration: line-through; }
  [data-th-main=hotdog] a.user-blocked.with-style {
    color: #000000;
    text-decoration: line-through; }

a.user-blocked.with-style:hover {
  color: #f68b00; }
  [data-th-main=pony] a.user-blocked.with-style:hover {
    color: #2e76b4; }
  [data-th-main=bloodlust] a.user-blocked.with-style:hover {
    color: #f68b00; }
  [data-th-main=serpent] a.user-blocked.with-style:hover {
    color: #3A8F3A; }
  [data-th-main=hotdog] a.user-blocked.with-style:hover {
    color: #999999; }

.comment-vote-down-link, .comment-vote-up-link, .post-vote-up-link, .post-vote-down-link {
  cursor: pointer; }

#c-admin-dashboards .section {
  margin-bottom: 2em; }

#c-maintenance-user-api-keys .button_to {
  display: inline-block; }

div#c-artists span.new-artist, div#excerpt span.new-artist {
  font-weight: bold;
  color: #A00; }

div#c-artists ul ul, div#excerpt ul ul {
  margin-left: 1em; }

div#c-artists div#a-edit textarea, div#c-artists div#a-new textarea, div#excerpt div#a-edit textarea, div#excerpt div#a-new textarea {
  height: 10em; }
  div#c-artists div#a-edit textarea#artist_other_names_string, div#c-artists div#a-new textarea#artist_other_names_string, div#excerpt div#a-edit textarea#artist_other_names_string, div#excerpt div#a-new textarea#artist_other_names_string {
    height: 3em; }

div#c-artists div#a-edit .hint, div#c-artists div#a-new .hint, div#excerpt div#a-edit .hint, div#excerpt div#a-new .hint {
  display: block; }

div#c-artists div.recent-posts, div#excerpt div.recent-posts {
  margin-top: 1em; }
  div#c-artists div.recent-posts h1, div#excerpt div.recent-posts h1 {
    font-size: 1.5em;
    margin: 0; }

#c-bans #a-index tr[data-expired="true"] {
  background-color: #3e9e49 !important; }
  #c-bans #a-index tr[data-expired="true"]:hover {
    background-color: #378c41 !important; }

#c-bans #a-index tr[data-expired="false"] {
  background-color: #e45f5f !important; }
  #c-bans #a-index tr[data-expired="false"]:hover {
    background-color: #e04949 !important; }

div#c-blips article.blip {
  margin-bottom: 1rem;
  word-wrap: break-word; }
  div#c-blips article.blip[data-is-deleted="true"] {
    background-color: #822828; }

form.edit_blip div.input.boolean {
  display: inline-block; }
  form.edit_blip div.input.boolean label {
    font-weight: normal;
    vertical-align: initial; }

#c-bulk-update-requests s.approved {
  color: green; }

#c-bulk-update-requests s.failed {
  color: red; }

div.comments-for-post div.notices {
  margin: 1em 0; }

div.comments-for-post div.list-of-comments article.comment {
  border-radius: 3px;
  word-wrap: break-word;
  margin-bottom: 0.5rem; }
  div.comments-for-post div.list-of-comments article.comment[data-is-sticky="true"] {
    background: #204889; }
  div.comments-for-post div.list-of-comments article.comment[data-is-deleted="true"] {
    background: #822828; }
  div.comments-for-post div.list-of-comments article.comment div.content .comment-vote-up-link:hover, div.comments-for-post div.list-of-comments article.comment div.content .comment-vote-down-link:hover {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 3px; }

div.comments-for-post div.list-of-comments article.comment.below-threshold:not([data-is-sticky="true"]) {
  opacity: 0.3; }

div.comments-for-post div.list-of-comments article.comment.below-threshold:hover {
  opacity: 1.0; }

div#c-comments div#a-index div.header span.info, div#c-comments div#a-show div.header span.info {
  margin-right: 1.5em; }

div#c-comments div#a-index div.header strong, div#c-comments div#a-index div.header time, div#c-comments div#a-show div.header strong, div#c-comments div#a-show div.header time {
  margin-right: 0.3em; }

div#c-comments div#a-index div.header div.notices, div#c-comments div#a-show div.header div.notices {
  margin: 1em 0; }

div#c-comments div#a-index div.header div.list-of-tags .comment-tag-hide-link::before, div#c-comments div#a-show div.header div.list-of-tags .comment-tag-hide-link::before {
  content: "Hide Tags"; }

div#c-comments div#a-index div.header div.list-of-tags.hidden .tag-list, div#c-comments div#a-show div.header div.list-of-tags.hidden .tag-list {
  display: none; }

div#c-comments div#a-index div.header div.list-of-tags.hidden .comment-tag-hide-link::before, div#c-comments div#a-show div.header div.list-of-tags.hidden .comment-tag-hide-link::before {
  content: "Show Tags"; }

div#c-comments div#a-index .comment-post {
  border-left: 0.25rem solid #427982;
  background: rgba(77, 77, 77, 0.7);
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-bottom: 0.25rem;
  margin-bottom: 1rem; }
  [data-th-main=pony] div#c-comments div#a-index .comment-post {
    border-left: 0.25rem solid #427982;
    background: rgba(92, 66, 140, 0.7); }
  [data-th-main=bloodlust] div#c-comments div#a-index .comment-post {
    border-left: 0.25rem solid #427982;
    background: rgba(77, 77, 77, 0.7); }
  [data-th-main=serpent] div#c-comments div#a-index .comment-post {
    border-left: 0.25rem solid #427982;
    background: rgba(255, 249, 190, 0.7); }
  [data-th-main=hotdog] div#c-comments div#a-index .comment-post {
    border-left: 0.25rem solid #427982;
    background: rgba(209, 98, 95, 0.7); }
  div#c-comments div#a-index .comment-post .comments {
    margin-left: 2rem; }

div#c-comments div#a-index .post-container {
  display: flex; }
  div#c-comments div#a-index .post-container .post {
    width: 165px; }

div#c-comments div#a-index div.comments-for-post .comment-post {
  display: flex; }
  div#c-comments div#a-index div.comments-for-post .comment-post .comments {
    margin-left: 0;
    width: 100%; }

@media screen and (max-width: 800px) {
  body.resp div#c-comments div#a-index .comment-post .comments {
    margin-left: 1rem; }
  body.resp div#c-comments div#a-index .post-container .post-information {
    margin-left: 0.5rem; }
  body.resp div#c-comments div#a-index .post-container .post {
    width: unset; }
  body.resp div#c-comments div#a-index div.comments-for-post .comment-post {
    display: block; }
    body.resp div#c-comments div#a-index div.comments-for-post .comment-post .comments {
      width: unset; } }

form.edit_comment div.input.boolean {
  display: inline-block; }
  form.edit_comment div.input.boolean label {
    font-weight: normal;
    vertical-align: initial; }

div#c-dmails tr.read-false {
  font-weight: bold; }

div#c-dmails div#preview {
  margin: 1em 0;
  border: 1px solid #CCC;
  padding: 1em;
  width: 40em; }
  div#c-dmails div#preview h1 {
    font-size: 1.5em; }

#c-edit-history .edit-item {
  display: flex; }
  #c-edit-history .edit-item > div {
    margin-right: 1em;
    display: inline-block; }

#c-edit-history ins {
  background-color: #3e9e49;
  font-weight: bold; }

#c-edit-history del {
  background-color: #e45f5f;
  font-weight: bold; }

ul.backtrace {
  font-family: monospace;
  font-size: 1.2em;
  list-style-type: none;
  background: #284a81;
  padding: 1em;
  margin-bottom: 1em; }

div#c-explore-posts header {
  text-align: center; }
  div#c-explore-posts header h1 {
    font-size: 3em; }
  div#c-explore-posts header p.byline {
    font-size: 1.2em;
    font-style: italic; }

div#c-explore-posts div.posts {
  padding: 1em;
  margin-bottom: 2em; }

div#c-explore-posts div#a-intro {
  width: 870px;
  margin: 0 auto;
  text-align: center; }

div#c-favorites section#content > h1 {
  font-size: 1.16667em; }

div.list-of-forum-posts article {
  margin: 1em 0em;
  word-wrap: break-word; }
  div.list-of-forum-posts article a.voted {
    font-weight: bold; }
  div.list-of-forum-posts article span.desc {
    color: grey; }
  div.list-of-forum-posts article:target {
    background-color: rgba(255, 255, 255, 0.25); }
  div.list-of-forum-posts article[data-is-deleted="true"] {
    background-color: rgba(130, 40, 40, 0.75); }
  div.list-of-forum-posts article .vote-score-up {
    color: green; }
  div.list-of-forum-posts article .vote-score-meh {
    color: goldenrod; }
  div.list-of-forum-posts article .vote-score-down {
    color: red; }
  div.list-of-forum-posts article div.content-menu .edit_forum_post, div.list-of-forum-posts article div.content-menu .edit_forum_topic {
    display: none; }
  div.list-of-forum-posts article div.content-menu menu {
    margin-top: 0.5em; }
    div.list-of-forum-posts article div.content-menu menu ul.votes {
      margin: 0.5em 0; }
    div.list-of-forum-posts article div.content-menu menu li {
      margin-right: 1em; }

div#c-forum-topics span.info {
  color: #AAA; }

div#c-forum-topics span.new {
  font-size: 80%;
  color: red;
  margin-right: 0.5em; }

div#c-forum-topics span.locked-topic {
  color: gray; }

div#c-forum-topics span.level-topic {
  color: #f66;
  font-weight: bold; }

div#c-forum-topics tr.forum-topic-row td:last-child {
  white-space: nowrap; }

div#c-forum-topics tr.forum-topic-row[data-is-deleted="true"] {
  background-color: rgba(130, 40, 40, 0.75); }

#c-forum-posts #a-index tr[data-topic-is-deleted="true"] .forum-post-topic-title::after,
#c-forum-posts #a-index tr[data-is-deleted="true"] .forum-post-excerpt::after {
  content: " (deleted)";
  color: gray; }

#c-forum-posts #a-index tr[data-is-deleted="true"], #c-forum-posts #a-index tr[data-topic-is-deleted="true"] {
  background-color: rgba(130, 40, 40, 0.75); }

.guest-warning {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  background-color: #031131; }
  .guest-warning .guest-warning-dialog {
    z-index: 100;
    position: relative;
    top: 20vh;
    margin-left: auto;
    margin-right: auto;
    height: 40vh;
    width: 40vw;
    overflow-x: scroll;
    background-color: #284a81;
    border: 1px solid #0a1220; }
    .guest-warning .guest-warning-dialog .dialog-header {
      padding: 0.5rem 1rem;
      background-color: rgba(255, 255, 255, 0.05);
      border-bottom: 1px solid #0a1220; }
    .guest-warning .guest-warning-dialog .dialog-content {
      padding: 0.5rem 1rem; }
    .guest-warning .guest-warning-dialog .dialog-footer {
      padding: 0.5rem 1rem; }

div#c-iqdb-queries div#a-check article.post-preview {
  height: 180px;
  width: 180px;
  border: lightgrey solid 1px; }
  div#c-iqdb-queries div#a-check article.post-preview img {
    margin-top: 10px; }

div#c-iqdb-queries div#a-check #filedropzone {
  margin: 1em 0; }

div#c-static div#a-keyboard-shortcuts {
  overflow: visible; }
  div#c-static div#a-keyboard-shortcuts section {
    min-width: 20%;
    float: left;
    margin-bottom: 1em; }
    div#c-static div#a-keyboard-shortcuts section h1 {
      font-size: 1.16667em; }
    div#c-static div#a-keyboard-shortcuts section li {
      margin-bottom: 0.5em; }

div#c-maintenance-user-login-reminders div#a-new {
  width: 50em; }

#c-meta-searches section {
  margin-bottom: 2em; }

div#c-moderator-dashboards div#col1 {
  width: 45%;
  float: left;
  padding-right: 5%; }

div#c-moderator-dashboards div#col2 {
  width: 45%;
  float: left; }

div#c-moderator-dashboards div.activity {
  margin-bottom: 1em; }

div#c-moderator-dashboards div#ip-addr-search {
  margin-bottom: 2em; }
  div#c-moderator-dashboards div#ip-addr-search .hint {
    display: block; }

div#c-moderator-dashboards div#activity-search {
  margin-bottom: 2em; }

div#c-moderator-dashboards table {
  width: 100%; }

div#c-moderator-dashboards caption {
  font-size: 1.1em;
  font-weight: bold;
  text-align: left; }

div#c-moderator-dashboards th {
  text-align: left; }

div#c-moderator-post-queues div.post {
  margin-bottom: 4em;
  overflow: hidden; }
  div#c-moderator-post-queues div.post.post-pos-score {
    background: #e6ffe6; }
  div#c-moderator-post-queues div.post.post-neg-score {
    background: #ffe6e6; }
  div#c-moderator-post-queues div.post aside {
    float: left;
    width: 220px;
    text-align: right;
    padding-right: 20px; }
  div#c-moderator-post-queues div.post section {
    float: left;
    width: 800px; }
  div#c-moderator-post-queues div.post[data-tags~=animated] aside:before, div#c-moderator-post-queues div.post[data-file-ext=swf] aside:before, div#c-moderator-post-queues div.post[data-file-ext=webm] aside:before, div#c-moderator-post-queues div.post[data-file-ext=zip] aside:before, div#c-moderator-post-queues div.post[data-file-ext=mp4] aside:before {
    content: "►";
    position: absolute;
    width: 20px;
    height: 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 2px;
    text-align: center; }
  div#c-moderator-post-queues div.post[data-has-sound=true] aside:before {
    content: "♪";
    position: absolute;
    width: 20px;
    height: 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 2px;
    text-align: center; }

div#c-moderator-post-queues div#moderation-guideline {
  width: 900px; }
  div#c-moderator-post-queues div#moderation-guideline h1 {
    font-size: 1.5em; }

div#c-news-updates div#a-edit .hint, div#c-news-updates div#a-new .hint {
  display: block; }

div#note-container {
  position: absolute;
  z-index: 50; }
  div#note-container div.note-body {
    position: absolute;
    border: 1px solid black;
    background: #FFE;
    color: black;
    min-width: 140px;
    min-height: 1em;
    line-height: normal;
    cursor: pointer;
    padding: 4px;
    z-index: 150;
    overflow: auto; }
    div#note-container div.note-body h1, div#note-container div.note-body h2, div#note-container div.note-body h3, div#note-container div.note-body h4, div#note-container div.note-body h5, div#note-container div.note-body h6, div#note-container div.note-body a, div#note-container div.note-body span, div#note-container div.note-body div, div#note-container div.note-body blockquote, div#note-container div.note-body br, div#note-container div.note-body p, div#note-container div.note-body ul, div#note-container div.note-body li, div#note-container div.note-body ol, div#note-container div.note-body em, div#note-container div.note-body strong, div#note-container div.note-body small, div#note-container div.note-body big, div#note-container div.note-body b, div#note-container div.note-body i, div#note-container div.note-body font, div#note-container div.note-body u, div#note-container div.note-body s, div#note-container div.note-body code, div#note-container div.note-body center {
      line-height: normal; }
    div#note-container div.note-body > :last-child {
      margin-bottom: 0; }
    div#note-container div.note-body b, div#note-container div.note-body strong {
      font-weight: bold; }
    div#note-container div.note-body i, div#note-container div.note-body em {
      font-style: italic; }
    div#note-container div.note-body small {
      font-size: 0.8em; }
    div#note-container div.note-body ruby rt {
      font-size: 0.8em; }
    div#note-container div.note-body ul {
      margin-left: 1em;
      margin-bottom: 1em; }
      div#note-container div.note-body ul ul {
        margin-bottom: 0; }
      div#note-container div.note-body ul li {
        list-style-type: disc; }
        div#note-container div.note-body ul li + br {
          display: none; }
  div#note-container div.note-box {
    position: absolute;
    border: 1px solid white;
    min-width: 5px;
    min-height: 5px;
    width: 100px;
    height: 100px;
    cursor: move;
    background: transparent;
    line-height: normal; }
    div#note-container div.note-box div.note-box-inner-border {
      border: 1px solid black;
      background: #FFE; }
    div#note-container div.note-box div.note-box-inner-border.unsaved {
      border: 1px solid red; }
    div#note-container div.note-box.embedded div.bg {
      height: 100%;
      text-align: center;
      display: table-cell;
      vertical-align: middle; }
    div#note-container div.note-box.embedded div.note-box-inner-border {
      text-align: center;
      display: table-cell;
      vertical-align: middle; }
    div#note-container div.note-box.note-box-highlighted {
      outline: 2px solid #00F; }

div#note-preview {
  position: absolute;
  border: 1px solid red;
  opacity: 0.6;
  display: none;
  background: white; }

div.note-edit-dialog {
  font-size: 0.8em; }

a.pool-category-series, .pool-category-series a {
  color: #b4c7d9; }
  a.pool-category-series:hover, .pool-category-series a:hover {
    color: #2e76b4; }

a.pool-category-collection, .pool-category-collection a {
  color: #b4c7d9; }
  a.pool-category-collection:hover, .pool-category-collection a:hover {
    color: #2e76b4; }

div#add-to-pool-dialog {
  font-size: 0.8em; }
  div#add-to-pool-dialog form {
    margin-bottom: 1em; }
  div#add-to-pool-dialog li {
    margin-left: 1em;
    cursor: pointer; }
  div#add-to-pool-dialog .hint {
    display: block; }

div#c-pools div#a-show #blacklist-box {
  display: inline; }

div#c-pools div#a-show div#description {
  margin-bottom: 1em; }

div#c-pool-orders div#a-edit ul.ui-sortable, div#c-pool-orders div#a-import ul.ui-sortable, div#c-pool-import div#a-edit ul.ui-sortable, div#c-pool-import div#a-import ul.ui-sortable, div#c-favorite-group-orders div#a-edit ul.ui-sortable, div#c-favorite-group-orders div#a-import ul.ui-sortable {
  list-style-type: none; }
  div#c-pool-orders div#a-edit ul.ui-sortable li, div#c-pool-orders div#a-import ul.ui-sortable li, div#c-pool-import div#a-edit ul.ui-sortable li, div#c-pool-import div#a-import ul.ui-sortable li, div#c-favorite-group-orders div#a-edit ul.ui-sortable li, div#c-favorite-group-orders div#a-import ul.ui-sortable li {
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 20px;
    min-width: 150px;
    min-height: 150px;
    padding: 10px;
    display: inline-block; }
  div#c-pool-orders div#a-edit ul.ui-sortable li.ui-state-default, div#c-pool-orders div#a-import ul.ui-sortable li.ui-state-default, div#c-pool-import div#a-edit ul.ui-sortable li.ui-state-default, div#c-pool-import div#a-import ul.ui-sortable li.ui-state-default, div#c-favorite-group-orders div#a-edit ul.ui-sortable li.ui-state-default, div#c-favorite-group-orders div#a-import ul.ui-sortable li.ui-state-default {
    background: none; }
  div#c-pool-orders div#a-edit ul.ui-sortable li.ui-state-placeholder, div#c-pool-orders div#a-import ul.ui-sortable li.ui-state-placeholder, div#c-pool-import div#a-edit ul.ui-sortable li.ui-state-placeholder, div#c-pool-import div#a-import ul.ui-sortable li.ui-state-placeholder, div#c-favorite-group-orders div#a-edit ul.ui-sortable li.ui-state-placeholder, div#c-favorite-group-orders div#a-import ul.ui-sortable li.ui-state-placeholder {
    background: none; }

ul.inline-pool-list {
  display: inline; }
  ul.inline-pool-list li {
    display: inline; }

div#c-post-events #a-index tr.resolved-false {
  background: #822828; }

div#c-post-flags tr.resolved-false {
  background: #822828 !important; }

#page.mode-edit {
  background-color: #828028; }
  [data-th-main=pony] #page.mode-edit {
    background-color: #828028; }
  [data-th-main=bloodlust] #page.mode-edit {
    background-color: #828028; }
  [data-th-main=serpent] #page.mode-edit {
    background-color: #828028; }
  [data-th-main=hotdog] #page.mode-edit {
    background-color: #828028; }

#page.mode-tag-script {
  background-color: #4f114f; }
  [data-th-main=pony] #page.mode-tag-script {
    background-color: #4f114f; }
  [data-th-main=bloodlust] #page.mode-tag-script {
    background-color: #4f114f; }
  [data-th-main=serpent] #page.mode-tag-script {
    background-color: #4f114f; }
  [data-th-main=hotdog] #page.mode-tag-script {
    background-color: #4f114f; }

#page.mode-add-fav {
  background-color: #104e17; }
  [data-th-main=pony] #page.mode-add-fav {
    background-color: #104e17; }
  [data-th-main=bloodlust] #page.mode-add-fav {
    background-color: #104e17; }
  [data-th-main=serpent] #page.mode-add-fav {
    background-color: #104e17; }
  [data-th-main=hotdog] #page.mode-add-fav {
    background-color: #104e17; }

#page.mode-remove-fav {
  background-color: #07240b; }
  [data-th-main=pony] #page.mode-remove-fav {
    background-color: #07240b; }
  [data-th-main=bloodlust] #page.mode-remove-fav {
    background-color: #07240b; }
  [data-th-main=serpent] #page.mode-remove-fav {
    background-color: #07240b; }
  [data-th-main=hotdog] #page.mode-remove-fav {
    background-color: #07240b; }

#page.mode-vote-up {
  background-color: #288233; }
  [data-th-main=pony] #page.mode-vote-up {
    background-color: #288233; }
  [data-th-main=bloodlust] #page.mode-vote-up {
    background-color: #288233; }
  [data-th-main=serpent] #page.mode-vote-up {
    background-color: #288233; }
  [data-th-main=hotdog] #page.mode-vote-up {
    background-color: #288233; }

#page.mode-vote-down {
  background-color: #822828; }
  [data-th-main=pony] #page.mode-vote-down {
    background-color: #822828; }
  [data-th-main=bloodlust] #page.mode-vote-down {
    background-color: #822828; }
  [data-th-main=serpent] #page.mode-vote-down {
    background-color: #822828; }
  [data-th-main=hotdog] #page.mode-vote-down {
    background-color: #822828; }

#page.mode-lock-rating {
  background-color: #AA3; }
  [data-th-main=pony] #page.mode-lock-rating {
    background-color: #AA3; }
  [data-th-main=bloodlust] #page.mode-lock-rating {
    background-color: #AA3; }
  [data-th-main=serpent] #page.mode-lock-rating {
    background-color: #AA3; }
  [data-th-main=hotdog] #page.mode-lock-rating {
    background-color: #AA3; }

#page.mode-lock-note {
  background-color: #3AA; }
  [data-th-main=pony] #page.mode-lock-note {
    background-color: #3AA; }
  [data-th-main=bloodlust] #page.mode-lock-note {
    background-color: #3AA; }
  [data-th-main=serpent] #page.mode-lock-note {
    background-color: #3AA; }
  [data-th-main=hotdog] #page.mode-lock-note {
    background-color: #3AA; }

#page.mode-approve {
  background-color: #48C; }
  [data-th-main=pony] #page.mode-approve {
    background-color: #48C; }
  [data-th-main=bloodlust] #page.mode-approve {
    background-color: #48C; }
  [data-th-main=serpent] #page.mode-approve {
    background-color: #48C; }
  [data-th-main=hotdog] #page.mode-approve {
    background-color: #48C; }

#page.mode-notes {
  background-color: #09262b; }
  [data-th-main=pony] #page.mode-notes {
    background-color: #09262b; }
  [data-th-main=bloodlust] #page.mode-notes {
    background-color: #09262b; }
  [data-th-main=serpent] #page.mode-notes {
    background-color: #09262b; }
  [data-th-main=hotdog] #page.mode-notes {
    background-color: #09262b; }

#page.mode-delete {
  background-color: #4e1010; }
  [data-th-main=pony] #page.mode-delete {
    background-color: #4e1010; }
  [data-th-main=bloodlust] #page.mode-delete {
    background-color: #4e1010; }
  [data-th-main=serpent] #page.mode-delete {
    background-color: #4e1010; }
  [data-th-main=hotdog] #page.mode-delete {
    background-color: #4e1010; }

#mode-box-mode, #mode-box #set-id {
  width: 15em; }

@keyframes heartbeat {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.3); }
  100% {
    transform: scale(1); } }

@keyframes sharpen {
  from {
    filter: blur(8px); }
  to {
    filter: none; } }

article.post-preview {
  box-sizing: border-box;
  height: 154px;
  width: 154px;
  margin: 0 10px 10px 0;
  overflow: hidden;
  text-align: center;
  display: inline-block;
  position: relative; }
  article.post-preview a {
    display: inline-block; }
  article.post-preview.captioned {
    height: auto;
    vertical-align: text-top; }
  article.post-preview .desc {
    background-color: #404040;
    font-size: 80%;
    margin-bottom: 0; }
    [data-th-main=pony] article.post-preview .desc {
      background-color: #503a7a; }
    [data-th-main=bloodlust] article.post-preview .desc {
      background-color: #404040; }
    [data-th-main=serpent] article.post-preview .desc {
      background-color: #fff6a5; }
    [data-th-main=hotdog] article.post-preview .desc {
      background-color: #cc4e4b; }
  article.post-preview .post-score > span {
    font-size: 0.8rem;
    margin-left: 0.5em; }
  article.post-preview.post-rating-explicit .post-score-rating {
    color: #e45f5f; }
  article.post-preview.post-rating-safe .post-score-rating {
    color: #3e9e49; }
  article.post-preview.post-rating-questionable .post-score-rating {
    color: #ffe666; }
  article.post-preview img {
    box-sizing: border-box;
    margin: auto;
    max-height: 10rem;
    max-width: 10rem;
    border-radius: 3px 3px 0 0; }
  article.post-preview[data-tags~=animated] > a:before {
    background-color: #333333;
    border: 1px solid #ffffff;
    color: #ffffff;
    z-index: 5;
    position: absolute;
    left: 0;
    top: 0.5rem;
    text-align: center;
    border-radius: 6px;
    padding: 3px 5px;
    font-size: 60%;
    font-weight: bold;
    content: "ANIM"; }
    [data-th-main=pony] article.post-preview[data-tags~=animated] > a:before {
      background-color: #453269;
      border: 1px solid #ffffff;
      color: #ffffff; }
    [data-th-main=bloodlust] article.post-preview[data-tags~=animated] > a:before {
      background-color: #333333;
      border: 1px solid #ffffff;
      color: #ffffff; }
    [data-th-main=serpent] article.post-preview[data-tags~=animated] > a:before {
      background-color: #fff48b;
      border: 1px solid #ffffff;
      color: #000000; }
    [data-th-main=hotdog] article.post-preview[data-tags~=animated] > a:before {
      background-color: #c53c38;
      border: 1px solid #ffffff;
      color: #000000; }
  article.post-preview[data-file-ext=webm] > a:before {
    background-color: #333333;
    border: 1px solid #ffffff;
    color: #ffffff;
    z-index: 5;
    position: absolute;
    left: 0;
    top: 0.5rem;
    text-align: center;
    border-radius: 6px;
    padding: 3px 5px;
    font-size: 60%;
    font-weight: bold;
    content: "WEBM"; }
    [data-th-main=pony] article.post-preview[data-file-ext=webm] > a:before {
      background-color: #453269;
      border: 1px solid #ffffff;
      color: #ffffff; }
    [data-th-main=bloodlust] article.post-preview[data-file-ext=webm] > a:before {
      background-color: #333333;
      border: 1px solid #ffffff;
      color: #ffffff; }
    [data-th-main=serpent] article.post-preview[data-file-ext=webm] > a:before {
      background-color: #fff48b;
      border: 1px solid #ffffff;
      color: #000000; }
    [data-th-main=hotdog] article.post-preview[data-file-ext=webm] > a:before {
      background-color: #c53c38;
      border: 1px solid #ffffff;
      color: #000000; }

#saved-searches-nav {
  margin-top: 1em; }

#excerpt p.links {
  margin-top: 1em; }

#edit-dialog textarea {
  margin-bottom: 0.25em; }

.post-preview {
  /* Pending and flagged posts have blue borders (except in the modqueue). */ }
  .post-preview.post-status-has-children img {
    border: 2px solid transparent;
    border-color: #0F0; }
  .post-preview.post-status-has-parent img {
    border: 2px solid transparent;
    border-color: #CC0; }
  .post-preview.post-status-has-children.post-status-has-parent img {
    border: 2px solid transparent;
    border-color: #0F0 #CC0 #CC0 #0F0; }
  .post-preview.post-status-deleted img {
    border: 2px solid transparent;
    border-color: #000; }
  .post-preview.post-status-has-children.post-status-deleted img {
    border: 2px solid transparent;
    border-color: #0F0 #000 #000 #0F0; }
  .post-preview.post-status-has-parent.post-status-deleted img {
    border: 2px solid transparent;
    border-color: #CC0 #000 #000 #CC0; }
  .post-preview.post-status-has-children.post-status-has-parent.post-status-deleted img {
    border: 2px solid transparent;
    border-color: #0F0 #000 #000 #CC0; }
  .post-preview.post-status-pending:not(.mod-queue-preview) img,
  .post-preview.post-status-flagged:not(.mod-queue-preview) img {
    border: 2px solid transparent;
    border-color: #00F; }
  .post-preview.post-status-has-children.post-status-pending:not(.mod-queue-preview) img,
  .post-preview.post-status-has-children.post-status-flagged:not(.mod-queue-preview) img {
    border: 2px solid transparent;
    border-color: #0F0 #00F #00F #0F0; }
  .post-preview.post-status-has-parent.post-status-pending:not(.mod-queue-preview) img,
  .post-preview.post-status-has-parent.post-status-flagged:not(.mod-queue-preview) img {
    border: 2px solid transparent;
    border-color: #CC0 #00F #00F #CC0; }
  .post-preview.post-status-has-children.post-status-has-parent.post-status-pending:not(.mod-queue-preview) img,
  .post-preview.post-status-has-children.post-status-has-parent.post-status-flagged:not(.mod-queue-preview) img {
    border: 2px solid transparent;
    border-color: #0F0 #00F #00F #CC0; }

/* Flagged posts have red borders for approvers. */
body[data-user-can-approve-posts="true"] .post-preview.post-status-flagged img {
  border: 2px solid transparent;
  border-color: #F00; }

body[data-user-can-approve-posts="true"] .post-preview.post-status-has-children.post-status-flagged img {
  border: 2px solid transparent;
  border-color: #0F0 #F00 #F00 #0F0; }

body[data-user-can-approve-posts="true"] .post-preview.post-status-has-parent.post-status-flagged img {
  border: 2px solid transparent;
  border-color: #CC0 #F00 #F00 #CC0; }

body[data-user-can-approve-posts="true"] .post-preview.post-status-has-children.post-status-has-parent.post-status-flagged img {
  border: 2px solid transparent;
  border-color: #0F0 #F00 #F00 #CC0; }

.post-preview.current-post {
  background-color: rgba(0, 0, 0, 0.1); }

#has-parent-relationship-preview, #has-children-relationship-preview {
  overflow-x: auto;
  white-space: nowrap; }
  #has-parent-relationship-preview article.post-preview, #has-children-relationship-preview article.post-preview {
    width: auto;
    border: none;
    margin: 0;
    padding: 5px 5px 10px; }

div#c-posts .source-link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 190px; }

div#c-posts .source-link:hover {
  overflow: visible;
  height: auto;
  z-index: 2;
  position: relative;
  max-width: none; }
  div#c-posts .source-link:hover a {
    background: #000000; }
    [data-th-main=pony] div#c-posts .source-link:hover a {
      background: #250D51; }
    [data-th-main=bloodlust] div#c-posts .source-link:hover a {
      background: #000000; }
    [data-th-main=serpent] div#c-posts .source-link:hover a {
      background: #44a544; }
    [data-th-main=hotdog] div#c-posts .source-link:hover a {
      background: #FFFF00; }

div#c-posts .fav-buttons {
  font-size: 14pt; }
  div#c-posts .fav-buttons i {
    margin-right: 0.1em; }
  div#c-posts .fav-buttons button.ui-button {
    padding: 0.25em 0.75em; }

div#c-posts .fav-buttons-true #add-fav-button {
  display: none; }

div#c-posts .fav-buttons-false #remove-fav-button {
  display: none; }

div#c-posts #ugoira-controls div#seek-slider {
  margin-top: 0.5em;
  float: right;
  overflow: visible;
  background: #EEE; }
  div#c-posts #ugoira-controls div#seek-slider .ui-progressbar-value {
    background: white; }

div#c-posts #ugoira-controls #save-video-link {
  margin: 0.5em 0; }

div#c-posts #ugoira-controls button {
  margin-top: 0.3em; }

div#c-posts #ugoira-controls #ugoira-load-progress {
  display: inline;
  margin-left: 17px; }

div#c-posts .outer-recent-searches {
  overflow: hidden; }

div#c-posts div.parent-children {
  display: flex;
  margin: -0.25rem;
  margin-bottom: 0.25rem; }
  div#c-posts div.parent-children div.notice {
    margin: 0 0.25rem; }
  div#c-posts div.parent-children div.notice-parent {
    flex-grow: 1; }

div#c-posts div.notice {
  border-radius: 3px;
  padding: 0.5em;
  margin-bottom: 0.5em;
  overflow: hidden; }
  div#c-posts div.notice ul {
    margin-left: 1em; }
  div#c-posts div.notice p {
    margin: 0; }
  div#c-posts div.notice .resolved {
    margin-left: 0.5em;
    font-weight: bold; }
  div#c-posts div.notice.notice-parent {
    background-color: #333333;
    border: 1px solid #222222; }
    [data-th-main=pony] div#c-posts div.notice.notice-parent {
      background-color: #453269;
      border: 1px solid #2f175c; }
    [data-th-main=bloodlust] div#c-posts div.notice.notice-parent {
      background-color: #333333;
      border: 1px solid #222222; }
    [data-th-main=serpent] div#c-posts div.notice.notice-parent {
      background-color: #fff48b;
      border: 1px solid #dacf6b; }
    [data-th-main=hotdog] div#c-posts div.notice.notice-parent {
      background-color: #c53c38;
      border: 1px solid #FF0000; }
  div#c-posts div.notice.notice-child {
    background-color: #333333;
    border: 1px solid #222222; }
    [data-th-main=pony] div#c-posts div.notice.notice-child {
      background-color: #453269;
      border: 1px solid #2f175c; }
    [data-th-main=bloodlust] div#c-posts div.notice.notice-child {
      background-color: #333333;
      border: 1px solid #222222; }
    [data-th-main=serpent] div#c-posts div.notice.notice-child {
      background-color: #fff48b;
      border: 1px solid #dacf6b; }
    [data-th-main=hotdog] div#c-posts div.notice.notice-child {
      background-color: #c53c38;
      border: 1px solid #FF0000; }
  div#c-posts div.notice.notice-pending {
    background-color: #333333;
    border: 1px solid #222222; }
    [data-th-main=pony] div#c-posts div.notice.notice-pending {
      background-color: #453269;
      border: 1px solid #2f175c; }
    [data-th-main=bloodlust] div#c-posts div.notice.notice-pending {
      background-color: #333333;
      border: 1px solid #222222; }
    [data-th-main=serpent] div#c-posts div.notice.notice-pending {
      background-color: #fff48b;
      border: 1px solid #dacf6b; }
    [data-th-main=hotdog] div#c-posts div.notice.notice-pending {
      background-color: #c53c38;
      border: 1px solid #FF0000; }
  div#c-posts div.notice.notice-no-shortcuts {
    background-color: #4d0000;
    border: 1px solid #222222; }
    [data-th-main=pony] div#c-posts div.notice.notice-no-shortcuts {
      background-color: #4d0000;
      border: 1px solid #2f175c; }
    [data-th-main=bloodlust] div#c-posts div.notice.notice-no-shortcuts {
      background-color: #4d0000;
      border: 1px solid #222222; }
    [data-th-main=serpent] div#c-posts div.notice.notice-no-shortcuts {
      background-color: #4d0000;
      border: 1px solid #dacf6b; }
    [data-th-main=hotdog] div#c-posts div.notice.notice-no-shortcuts {
      background-color: #4d0000;
      border: 1px solid #FF0000; }
  div#c-posts div.notice.notice-flagged {
    background-color: #4d0000;
    border: 1px solid #222222; }
    [data-th-main=pony] div#c-posts div.notice.notice-flagged {
      background-color: #4d0000;
      border: 1px solid #2f175c; }
    [data-th-main=bloodlust] div#c-posts div.notice.notice-flagged {
      background-color: #4d0000;
      border: 1px solid #222222; }
    [data-th-main=serpent] div#c-posts div.notice.notice-flagged {
      background-color: #4d0000;
      border: 1px solid #dacf6b; }
    [data-th-main=hotdog] div#c-posts div.notice.notice-flagged {
      background-color: #4d0000;
      border: 1px solid #FF0000; }
  div#c-posts div.notice.notice-deleted {
    background-color: #4d0000;
    border: 1px solid #222222; }
    [data-th-main=pony] div#c-posts div.notice.notice-deleted {
      background-color: #4d0000;
      border: 1px solid #2f175c; }
    [data-th-main=bloodlust] div#c-posts div.notice.notice-deleted {
      background-color: #4d0000;
      border: 1px solid #222222; }
    [data-th-main=serpent] div#c-posts div.notice.notice-deleted {
      background-color: #4d0000;
      border: 1px solid #dacf6b; }
    [data-th-main=hotdog] div#c-posts div.notice.notice-deleted {
      background-color: #4d0000;
      border: 1px solid #FF0000; }
  div#c-posts div.notice.notice-appealed {
    background-color: #333333;
    border: 1px solid #222222; }
    [data-th-main=pony] div#c-posts div.notice.notice-appealed {
      background-color: #453269;
      border: 1px solid #2f175c; }
    [data-th-main=bloodlust] div#c-posts div.notice.notice-appealed {
      background-color: #333333;
      border: 1px solid #222222; }
    [data-th-main=serpent] div#c-posts div.notice.notice-appealed {
      background-color: #fff48b;
      border: 1px solid #dacf6b; }
    [data-th-main=hotdog] div#c-posts div.notice.notice-appealed {
      background-color: #c53c38;
      border: 1px solid #FF0000; }
  div#c-posts div.notice.notice-resized {
    background-color: #333333;
    border: 1px solid #222222; }
    [data-th-main=pony] div#c-posts div.notice.notice-resized {
      background-color: #453269;
      border: 1px solid #2f175c; }
    [data-th-main=bloodlust] div#c-posts div.notice.notice-resized {
      background-color: #333333;
      border: 1px solid #222222; }
    [data-th-main=serpent] div#c-posts div.notice.notice-resized {
      background-color: #fff48b;
      border: 1px solid #dacf6b; }
    [data-th-main=hotdog] div#c-posts div.notice.notice-resized {
      background-color: #c53c38;
      border: 1px solid #FF0000; }

div#c-posts div.nav-notice {
  padding: 0.5em;
  margin-bottom: 0.5rem;
  background-color: #333333;
  position: relative; }
  [data-th-main=pony] div#c-posts div.nav-notice {
    background-color: #453269; }
  [data-th-main=bloodlust] div#c-posts div.nav-notice {
    background-color: #333333; }
  [data-th-main=serpent] div#c-posts div.nav-notice {
    background-color: #fff48b; }
  [data-th-main=hotdog] div#c-posts div.nav-notice {
    background-color: #c53c38; }
  div#c-posts div.nav-notice p {
    margin: 0;
    text-align: center; }

div#c-posts aside#sidebar #tag-list h2 {
  font-size: 1em; }

div#c-posts div#a-index menu#post-sections {
  margin-bottom: 0.5em;
  font-size: 1.16667em; }
  div#c-posts div#a-index menu#post-sections li {
    padding: 0 1em 0.5em 0; }
    div#c-posts div#a-index menu#post-sections li.active {
      font-weight: bold; }

div#c-posts div#a-show .active, div#c-posts div#a-show .pool-selected-true {
  font-weight: bold; }

div#c-posts div#a-show .recommended-posts {
  margin-top: 1em; }

div#c-posts div#a-show menu#post-sections {
  margin: 0;
  font-size: 1.16667em; }
  div#c-posts div#a-show menu#post-sections li {
    padding: 0 1em 0 0; }
  div#c-posts div#a-show menu#post-sections div.share a {
    margin: 0; }

div#c-posts div#a-show div.notices {
  margin: 1em 0; }

div#c-posts div#a-show span.quick-mod {
  float: right; }

div#c-posts div#a-show .pool-name, div#c-posts div#a-show .search-name, div#c-posts div#a-show .set-name {
  word-wrap: break-word;
  flex-grow: 2;
  text-align: center;
  max-width: 65%;
  padding: 0 1rem; }

div#c-posts div#a-show textarea {
  margin-bottom: 0.25em; }

div#c-posts div#a-show #edit .edit-submit {
  position: sticky;
  bottom: 0;
  background: #333333;
  width: min-content;
  padding: 1rem 1rem 1rem 0.5rem;
  margin: 0; }
  [data-th-main=pony] div#c-posts div#a-show #edit .edit-submit {
    background: #453269; }
  [data-th-main=bloodlust] div#c-posts div#a-show #edit .edit-submit {
    background: #333333; }
  [data-th-main=serpent] div#c-posts div#a-show #edit .edit-submit {
    background: #fff48b; }
  [data-th-main=hotdog] div#c-posts div#a-show #edit .edit-submit {
    background: #c53c38; }

div#c-posts div#a-show #edit textarea {
  width: 100%; }

div#c-posts div#a-show #favlist {
  margin-left: 1em;
  word-wrap: break-word; }

div#c-posts div#a-show .search-seq-nav + .pool-nav, div#c-posts div#a-show .search-seq-nav + .set-nav, div#c-posts div#a-show .pool-nav + .set-nav {
  margin-top: 0.5em; }

div#c-posts div#a-show .pool-nav, div#c-posts div#a-show .search-seq-nav, div#c-posts div#a-show .set-nav {
  background: #333333; }
  [data-th-main=pony] div#c-posts div#a-show .pool-nav, [data-th-main=pony] div#c-posts div#a-show .search-seq-nav, [data-th-main=pony] div#c-posts div#a-show .set-nav {
    background: #453269; }
  [data-th-main=bloodlust] div#c-posts div#a-show .pool-nav, [data-th-main=bloodlust] div#c-posts div#a-show .search-seq-nav, [data-th-main=bloodlust] div#c-posts div#a-show .set-nav {
    background: #333333; }
  [data-th-main=serpent] div#c-posts div#a-show .pool-nav, [data-th-main=serpent] div#c-posts div#a-show .search-seq-nav, [data-th-main=serpent] div#c-posts div#a-show .set-nav {
    background: #fff48b; }
  [data-th-main=hotdog] div#c-posts div#a-show .pool-nav, [data-th-main=hotdog] div#c-posts div#a-show .search-seq-nav, [data-th-main=hotdog] div#c-posts div#a-show .set-nav {
    background: #c53c38; }
  div#c-posts div#a-show .pool-nav li, div#c-posts div#a-show .search-seq-nav li, div#c-posts div#a-show .set-nav li {
    display: flex;
    height: 1.25rem;
    align-items: center;
    justify-content: space-between; }
    div#c-posts div#a-show .pool-nav li .first, div#c-posts div#a-show .pool-nav li .prev, div#c-posts div#a-show .search-seq-nav li .first, div#c-posts div#a-show .search-seq-nav li .prev, div#c-posts div#a-show .set-nav li .first, div#c-posts div#a-show .set-nav li .prev {
      flex-shrink: 2; }
    div#c-posts div#a-show .pool-nav li .next, div#c-posts div#a-show .pool-nav li .last, div#c-posts div#a-show .search-seq-nav li .next, div#c-posts div#a-show .search-seq-nav li .last, div#c-posts div#a-show .set-nav li .next, div#c-posts div#a-show .set-nav li .last {
      flex-shrink: 2; }

div#c-posts div#a-show span.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer; }

div#c-posts div#a-show div.comments-for-post {
  width: 100%; }

div#c-posts div#quick-edit-div textarea {
  width: 70%;
  height: 4em;
  display: block; }

div#c-post-versions div#a-index a, div#c-artist-versions div#a-index a {
  word-wrap: break-word; }

div#c-explore-posts a.desc {
  font-weight: bold;
  margin: 0 0.5em; }

div#c-explore-posts #popular-nav-links {
  text-align: center; }

div#c-explore-posts .period {
  margin: 0 5em; }
  div#c-explore-posts .period:first-child {
    margin-left: 0; }

div#unapprove-dialog p {
  margin-bottom: 1em; }

textarea[data-autocomplete="tag-edit"] {
  font-family: monospace; }

#image.fit-window {
  max-width: 100%; }

#image.fit-window-vertical {
  max-width: 100%;
  max-height: 95vh; }

section#image-extra-controls {
  display: flex;
  align-items: center; }
  section#image-extra-controls div {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-right: 0.5em; }

.flag-dialog-body label {
  white-space: normal; }

section#tag-list {
  word-break: break-word; }

#tag-list > ul {
  margin-bottom: 0.25rem; }

.tag-list-header {
  cursor: pointer; }
  .tag-list-header.hidden-category::before {
    content: "► "; }

.image-vote-buttons .post-score {
  padding: 0 0.5rem; }

div#image-and-nav {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, auto); }
  [data-th-nav=bottom] div#image-and-nav #nav-links-top {
    display: none; }
  [data-th-nav=top] div#image-and-nav #nav-links-bottom {
    display: none; }
  [data-th-nav=none] div#image-and-nav #nav-links-top, [data-th-nav=none] div#image-and-nav #nav-links-bottom {
    display: none; }

body[data-user-can-approve-posts="true"] .notice.notice-flagged .button, body[data-user-can-approve-posts="true"] .notice.notice-deleted .button, body[data-user-can-approve-posts="true"] .notice.notice-pending .button {
  font-size: 1.25rem;
  line-height: 1.5rem;
  padding: 0.25rem 0.5rem; }

div#c-post-versions div#a-index .desc-show {
  cursor: pointer; }

div#c-post-versions div#a-index .desc-popup {
  display: none;
  z-index: 10;
  width: 800px;
  height: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: auto;
  margin-left: -400px;
  margin-top: -200px;
  box-shadow: 0px 0px 15px #07162D, 0px 0px 15px #07162D, 0px 0px 15px #07162D !important; }

div#c-post-versions div#a-index .desc-popup-inner {
  height: 370px;
  overflow: auto; }

div#c-post-versions div#a-index .closebutton {
  float: right;
  cursor: pointer;
  margin-right: 10px;
  font-size: 14pt;
  font-weight: bold;
  color: white;
  vertical-align: center;
  margin-top: -3px; }

div#c-post-versions div#a-index .pv-label {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  background: #333333;
  padding: 0.25rem 0.5rem; }
  [data-th-main=pony] div#c-post-versions div#a-index .pv-label {
    background: #453269; }
  [data-th-main=bloodlust] div#c-post-versions div#a-index .pv-label {
    background: #333333; }
  [data-th-main=serpent] div#c-post-versions div#a-index .pv-label {
    background: #fff48b; }
  [data-th-main=hotdog] div#c-post-versions div#a-index .pv-label {
    background: #c53c38; }

div#c-post-versions div#a-index .pv-content {
  background: inherit;
  padding: 0.25rem 0.5rem; }

div#c-post-versions div#a-index .post-version {
  display: grid;
  border: 1px solid black;
  margin-bottom: 0.5rem;
  grid-template-columns: 2rem repeat(12, 1fr);
  grid-auto-rows: auto; }

div#c-post-versions div#a-index .pv-check {
  grid-row-start: 1;
  grid-row-end: 5;
  grid-column: 1;
  background: #284a81; }

div#c-post-versions div#a-index .pv-post-label {
  grid-row: 1;
  grid-column-start: 2;
  grid-column-end: 3; }

div#c-post-versions div#a-index .pv-post {
  grid-row: 2;
  grid-column-start: 2;
  grid-column-end: 3;
  border-dir: 1px solid black; }

div#c-post-versions div#a-index .pv-date-label {
  grid-row: 1;
  grid-column-start: 3;
  grid-column-end: 5; }

div#c-post-versions div#a-index .pv-date {
  grid-row: 2;
  grid-column-start: 3;
  grid-column-end: 5;
  border-dir: 1px solid black; }

div#c-post-versions div#a-index .pv-reason-label {
  grid-row: 1;
  grid-column-start: 5;
  grid-column-end: 7; }

div#c-post-versions div#a-index .pv-reason {
  grid-row: 2;
  grid-column-start: 5;
  grid-column-end: 7;
  border-dir: 1px solid black; }

div#c-post-versions div#a-index .pv-description-label {
  grid-row: 1;
  grid-column-start: 7;
  grid-column-end: 9; }

div#c-post-versions div#a-index .pv-description {
  grid-row: 2;
  grid-column-start: 7;
  grid-column-end: 9;
  border-dir: 1px solid black; }

div#c-post-versions div#a-index .pv-tags-locked-label {
  grid-row: 1;
  grid-column-start: 9;
  grid-column-end: 13; }

div#c-post-versions div#a-index .pv-tags-locked {
  grid-row: 2;
  grid-column-start: 9;
  grid-column-end: 13;
  border-dir: 1px solid black; }

div#c-post-versions div#a-index .pv-tags-label {
  grid-row: 3;
  grid-column-start: 2;
  grid-column-end: 10; }

div#c-post-versions div#a-index .pv-tags {
  grid-row: 4;
  grid-column-start: 2;
  grid-column-end: 10;
  border-dir: 1px solid black; }

div#c-post-versions div#a-index .pv-sources-label {
  grid-row: 3;
  grid-column-start: 10;
  grid-column-end: 14; }

div#c-post-versions div#a-index .pv-sources {
  grid-row: 4;
  grid-column-start: 10;
  grid-column-end: 14; }

div#c-post-versions div#a-index .pv-actions {
  grid-row: 2;
  grid-column: 13; }

div#c-post-versions div#a-index .pv-spacer {
  grid-row: 1;
  grid-column: 13; }

div.related-tags {
  width: 100%;
  margin-bottom: 1em;
  background: #3460a8;
  overflow: hidden;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap; }
  div.related-tags .related-section {
    display: flex;
    flex-direction: row;
    flex: 0 1 10%;
    padding: 5px 10px; }
    div.related-tags .related-section .related-title {
      padding: 0 5px;
      font-weight: bold; }
  div.related-tags .related-items {
    display: flex;
    flex-direction: column;
    margin: 0 -5px;
    padding: 0 5px; }
    div.related-tags .related-items .related-item {
      border: 1px solid rgba(0, 0, 0, 0.15);
      background: rgba(1, 1, 1, 0.15);
      border-radius: 2px;
      padding: 0 5px;
      max-width: 15rem;
      word-wrap: break-word;
      overflow-wrap: break-word; }
  div.related-tags .tag-active {
    background: #006ffa;
    color: white; }
  div.related-tags .tag-preview {
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: rgba(1, 1, 1, 0.15);
    border-radius: 2px;
    padding: 3px;
    margin-right: 5px;
    box-sizing: border-box; }
    div.related-tags .tag-preview .tag-preview-alias {
      background-color: rgba(150, 0, 0, 0.25); }
    div.related-tags .tag-preview .tag-preview-implication {
      background-color: rgba(0, 150, 0, 0.25); }

@media only screen and (orientation: portrait), (max-width: 1100px) {
  .related-section {
    flex: 0 1 50%; } }

div#c-sessions div#a-new label#remember-label {
  display: inline;
  font-weight: normal;
  font-style: italic; }

div#c-static div#a-site-map {
  width: 80em; }
  div#c-static div#a-site-map section {
    width: 20em;
    float: left; }
    div#c-static div#a-site-map section h1 {
      font-size: 1.16667em; }
    div#c-static div#a-site-map section ul {
      margin-bottom: 1.5em; }

div#c-tags div#a-index table.striped td:nth-child(1), div#c-tags div#a-index table.striped th:nth-child(1) {
  width: 5em;
  text-align: right; }

div#c-tags div#a-index table.striped td:nth-child(2), div#c-tags div#a-index table.striped th:nth-child(2) {
  padding-left: 1em;
  width: 40em; }

#tags-container {
  max-width: 70em; }
  #tags-container div.header {
    margin: 0;
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%; }
    #tags-container div.header label {
      grid-column: 1; }
    #tags-container div.header .options {
      grid-column: 2;
      justify-self: end; }
      #tags-container div.header .options .count {
        color: gray;
        text-decoration: italic;
        margin-left: 0.25em;
        padding-bottom: 0.2em; }
      #tags-container div.header .options i {
        margin-left: 0.25em;
        font-size: 11pt; }
      #tags-container div.header .options .fa-frown {
        color: red; }
      #tags-container div.header .options .fa-meh {
        color: darkkhaki; }
      #tags-container div.header .options .fa-smile {
        color: green; }

div#c-takedowns .takedown-vericode {
  border-radius: 3px;
  padding: 1rem;
  font-weight: bold;
  cursor: default; }

div#c-takedowns .takedown-instructions-header {
  font-size: 16pt;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px; }

div#c-takedowns #takedown-posts {
  overflow: hidden; }

div#c-takedowns #takedown-add-posts-tags-warning {
  display: none; }

div#c-takedowns #takedown-add-posts-tags-confirm, div#c-takedowns #takedown-add-posts-tags-cancel {
  display: none;
  background-color: #FFEB7E; }

div#c-takedowns #takedown-post-buttons {
  margin-bottom: 20px; }

div#c-takedowns .takedown-post-label {
  display: inline-block;
  height: 20px;
  width: 80px;
  vertical-align: middle;
  line-height: 20px;
  margin-bottom: 2px;
  margin-right: 5px;
  cursor: pointer; }

div#c-takedowns .takedown-postall-label {
  text-align: center; }

div#c-takedowns .takedown-post-label span {
  position: relative;
  top: -2px; }

div#c-takedowns .takedown-post-remove {
  width: 20px;
  background-color: #e45f5f;
  text-align: center; }

div#c-takedowns .takedown-post-keep {
  background-color: #3e9e49; }

div#c-takedowns .takedown-post-delete {
  background-color: #e45f5f; }

div#c-static div#a-terms-of-service {
  width: 40em; }
  div#c-static div#a-terms-of-service h1 {
    font-size: 1.5em; }
  div#c-static div#a-terms-of-service p {
    margin-bottom: 1em; }
  div#c-static div#a-terms-of-service ul {
    margin-left: 1em;
    margin-bottom: 1em; }
  div#c-static div#a-terms-of-service li {
    list-style-type: disc; }
  div#c-static div#a-terms-of-service section {
    margin-bottom: 2em; }

div#c-uploads div#a-new {
  /* Need to override this so it shows up at all. */ }
  div#c-uploads div#a-new .toggle-button {
    box-sizing: border-box;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: center;
    user-select: none;
    padding: .175em .5rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    margin: 0.25rem 0.5rem 0.25rem 0; }
  div#c-uploads div#a-new .toggle-button:last-child {
    margin-right: 0; }
  div#c-uploads div#a-new .toggle-button.active {
    background-color: #ffffff; }
    [data-th-main=pony] div#c-uploads div#a-new .toggle-button.active {
      background-color: #e8c446; }
    [data-th-main=bloodlust] div#c-uploads div#a-new .toggle-button.active {
      background-color: #ffffff; }
    [data-th-main=serpent] div#c-uploads div#a-new .toggle-button.active {
      background-color: #e8c446; }
    [data-th-main=hotdog] div#c-uploads div#a-new .toggle-button.active {
      background-color: #ffe380; }
  div#c-uploads div#a-new .flex-grid-outer {
    display: flex;
    padding: 10px 0; }
  div#c-uploads div#a-new .upload_preview_container.in-editor {
    display: none; }
  div#c-uploads div#a-new .upload_preview_container.in-sidebar {
    position: sticky;
    top: 20px; }
  div#c-uploads div#a-new .flex-grid {
    display: flex;
    padding: 10px 0; }
  div#c-uploads div#a-new .flex-wrap {
    display: flex;
    flex-wrap: wrap; }
  div#c-uploads div#a-new .col {
    flex: 1 0 0;
    margin-right: 5px; }
  div#c-uploads div#a-new .col2 {
    flex: 2 1 0; }
  div#c-uploads div#a-new .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25); }
  div#c-uploads div#a-new .section-label {
    white-space: normal;
    font-weight: bold; }
  div#c-uploads div#a-new .come-together-now {
    padding-bottom: 0; }
  div#c-uploads div#a-new .over-me {
    padding-top: 0; }
  div#c-uploads div#a-new .tag-textarea {
    display: inline-block;
    /* Why were we even unsetting this? It breaks EVERYTHING. */
    font-size: 1rem;
    width: 100%;
    resize: vertical; }
  div#c-uploads div#a-new #whitelist-warning {
    display: block;
    float: none; }
  div#c-uploads div#a-new .upload-source-input {
    margin-bottom: 2px; }
  @media only screen and (orientation: portrait), (max-width: 1100px) {
    div#c-uploads div#a-new #preview-sidebar {
      display: none; }
    div#c-uploads div#a-new .upload_preview_container.in-editor {
      display: flex;
      flex-direction: column-reverse; }
    div#c-uploads div#a-new .upload_preview_container.in-sidebar {
      display: none; }
    div#c-uploads div#a-new .upload_preview_dims {
      text-align: center; }
    div#c-uploads div#a-new .upload_preview_img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-height: 500px; }
    div#c-uploads div#a-new .below-upload > .upload_preview_img {
      max-height: 150px; }
    div#c-uploads div#a-new .related-section {
      flex: 0 1 50%; }
    div#c-uploads div#a-new .flex-grid {
      flex-direction: column; }
    div#c-uploads div#a-new input {
      max-width: 90%; } }
  div#c-uploads div#a-new .the_secret_switch {
    height: 10px;
    width: 100%; }

div#c-uploads div#a-index .info {
  margin-right: 1.5em; }

div#c-uploads div.upload-preview {
  display: inline-block; }
  div#c-uploads div.upload-preview > a {
    width: 154px;
    height: 154px;
    margin: 0 10px 10px 0;
    display: inline-block; }
    div#c-uploads div.upload-preview > a img {
      margin: auto;
      border: 2px solid transparent;
      max-width: 154px;
      max-height: 154px; }
  div#c-uploads div.upload-preview .caption-top {
    font-size: 0.8em;
    margin-bottom: 0; }

div#c-maintenance-user-deletions ul {
  margin-left: 1em;
  margin-bottom: 1em; }

div#c-maintenance-user-deletions li {
  list-style-type: disc; }

.user-feedback-positive {
  color: #3e9e49; }

.user-feedback-neutral {
  color: gray; }

.user-feedback-negative {
  color: #822828; }

div#c-user-feedbacks .feedback-category-positive, div#c-moderator-dashboards div#col2 .feedback-category-positive {
  background: green; }

div#c-user-feedbacks .feedback-category-negative, div#c-moderator-dashboards div#col2 .feedback-category-negative {
  background: #822828; }

div#c-user-feedbacks .feedback-category-neutral, div#c-moderator-dashboards div#col2 .feedback-category-neutral {
  background: #666; }

div#c-user-feedbacks blockquote, div#c-moderator-dashboards div#col2 blockquote {
  padding: 0.5em; }

div#c-user-feedbacks #negative-policy, div#c-moderator-dashboards div#col2 #negative-policy {
  max-width: 50em;
  margin-bottom: 1em; }

#c-user-name-change-requests .feedback-category-positive {
  background: #DDFFDD !important; }

#c-user-name-change-requests .feedback-category-negative {
  background: #FFDDDD !important; }

#c-user-name-change-requests li {
  margin-bottom: 1em; }

#c-user-name-change-requests form {
  margin-bottom: 2em; }

#c-user-name-change-requests p {
  margin: 0; }

#c-user-name-change-requests section {
  margin-bottom: 1em; }

.user-greeting-outer {
  padding: 0.25rem 0 0.25rem; }
  .user-greeting-outer span.user-greeting {
    color: gold;
    cursor: help; }

div#c-users div#a-show .profile-container {
  width: 100%; }

div#c-users div#a-show .bottom-section {
  display: flex; }

div#c-users div#a-show .stats-section {
  display: flex; }

div#c-users div#a-show .posts-section .posts {
  display: flex; }

div#c-users div#a-show .profile-avatar {
  margin-right: 2em; }

div#c-users div#a-show .about-section {
  flex-grow: 2;
  display: flex; }
  div#c-users div#a-show .about-section .about-piece {
    flex-grow: 1;
    margin: 0 1em; }
  div#c-users div#a-show .about-section div {
    flex-basis: 50%;
    overflow-wrap: break-word;
    word-break: break-all; }

div#c-users div#a-show div.box {
  margin-bottom: 2em; }
  div#c-users div#a-show div.box h2, div#c-users div#a-show div.box h3 {
    color: #333; }

div#c-users div#a-show .vertical-section {
  display: flex;
  flex-direction: column; }

div#c-users div#a-show .profile-stats {
  width: 100%;
  border-radius: 6px;
  background: rgba(51, 51, 51, 0.8); }
  [data-th-main=pony] div#c-users div#a-show .profile-stats {
    background: rgba(69, 50, 105, 0.8); }
  [data-th-main=bloodlust] div#c-users div#a-show .profile-stats {
    background: rgba(51, 51, 51, 0.8); }
  [data-th-main=serpent] div#c-users div#a-show .profile-stats {
    background: rgba(255, 244, 139, 0.8); }
  [data-th-main=hotdog] div#c-users div#a-show .profile-stats {
    background: rgba(197, 60, 56, 0.8); }
  div#c-users div#a-show .profile-stats abbr {
    cursor: help; }

div#c-users div#a-show .user-statistics {
  width: 100%; }
  div#c-users div#a-show .user-statistics tr {
    height: 1.75em; }
  div#c-users div#a-show .user-statistics th {
    width: 10%;
    text-align: right;
    padding-right: 1em;
    vertical-align: top; }
  div#c-users div#a-show .user-statistics td {
    width: 40%;
    vertical-align: top; }
  div#c-users div#a-show .user-statistics p {
    margin-bottom: 0.5em; }

div#c-users div#a-edit h1 {
  margin: 0.5em 0; }

div#c-users div#a-edit h2 {
  margin: 0.5em 0; }

div#c-users div#a-edit div.input {
  margin-bottom: 2em; }

div#c-users div#a-edit div.input span.hint {
  display: block;
  max-width: 70%; }

div#c-users div#a-edit .active {
  color: black; }

div#c-users div#a-new {
  max-width: 60em; }
  div#c-users div#a-new p {
    font-size: 1.2em;
    line-height: 1.4em; }

@media screen and (max-width: 800px) {
  body.resp div#c-users div#a-show .stats-section {
    flex-direction: column; }
  body.resp div#c-users div#a-show .bottom-section {
    flex-direction: column; }
  body.resp div#c-users div#a-show .vertical-section {
    flex-direction: row;
    flex-wrap: wrap; }
  body.resp div#c-users div#a-show .posts-section {
    order: 2; }
    body.resp div#c-users div#a-show .posts-section .posts {
      display: block; }
  body.resp div#c-users div#a-show .user-uploads {
    order: 1; }
  body.resp div#c-users div#a-show .about-section {
    flex-direction: column; }
  body.resp div#c-users div#a-show .about-piece {
    margin: 0;
    margin-bottom: 0.5rem; } }

div#c-wiki-pages form span.hint {
  display: block; }

div#c-wiki-pages #a-new div.notice {
  font-size: 0.8em;
  padding: 1em;
  margin: 1em 0; }

div#c-wiki-page-versions #a-diff del {
  background: red;
  text-decoration: none; }

div#c-wiki-page-versions #a-diff ins {
  background: green;
  text-decoration: none; }

div#c-wiki-page-versions #a-index table {
  margin-bottom: 1em; }

div#c-wiki-page-versions #a-show span.version {
  color: #AAA; }

.mobile-only {
  display: none; }

#desktop-version-link {
  display: none; }

#searchbox-redirect-link {
  display: none; }

@media screen and (max-width: 900px) {
  #ad-leaderboard {
    height: 50px;
    justify-content: center; }
    #ad-leaderboard .adzone {
      height: 50px;
      width: 320px; } }

@media screen and (max-width: 800px) {
  .mobile-only {
    display: unset; }
  body.resp {
    /* Make the quick search box in the navbar full width. */ }
    body.resp div#c-posts div#a-show .pool-nav li, body.resp div#c-posts div#a-show .search-seq-nav li, body.resp div#c-posts div#a-show .set-nav li {
      height: 2rem; }
    body.resp #search-box {
      display: none; }
    body.resp .mobile-search {
      display: block;
      margin: 0.5rem 0.5rem 1.5rem; }
      body.resp .mobile-search .search-help {
        font-size: 85%; }
    body.resp .mobile-search > .search-form > form {
      box-sizing: border-box;
      display: flex;
      width: 100%;
      margin-bottom: 0.25rem; }
      body.resp .mobile-search > .search-form > form .search-input {
        width: 100%;
        flex-grow: 1; }
        body.resp .mobile-search > .search-form > form .search-input input {
          width: 100%;
          box-sizing: border-box;
          border-radius: 3px 0 0 3px; }
      body.resp .mobile-search > .search-form > form .search-button > button {
        width: 32px;
        height: 32px;
        border-left: 1px solid #ccc;
        border-radius: 0 3px 3px 0; }
    body.resp .guest-warning .guest-warning-dialog {
      top: 5vh;
      height: 90vh;
      width: 80vw; }
    body.resp #desktop-version-link {
      display: inline; }
    body.resp #searchbox-redirect-link {
      display: inline; }
    body.resp #saved-searches-nav {
      display: none; }
    body.resp div#page aside#sidebar {
      padding: 5px; }
      body.resp div#page aside#sidebar input#tags {
        width: 80%; }
      body.resp div#page aside#sidebar button[type=submit] {
        font-size: 1.2em;
        background-color: #EEE;
        height: 42px;
        padding: 2px 10px;
        border: 1px solid #c5c5c5; }
    body.resp div#page {
      padding-left: 0;
      padding-right: 0; }
      body.resp div#page > div > div {
        display: flex;
        flex-direction: column;
        /* Move #sidebar below #content. */ }
        body.resp div#page > div > div > aside#sidebar {
          font-size: 1.5em;
          float: none;
          width: auto;
          order: 2; }
    body.resp header {
      position: relative; }
    body.resp #maintoggle {
      display: block;
      font-weight: bold;
      font-size: 2em; }
      body.resp #maintoggle.toggler-active {
        background-color: white;
        color: #000; }
    body.resp header#top menu form input {
      width: auto; }
    body.resp h1 {
      display: none; }
    body.resp form select, body.resp form input, body.resp form textarea, body.resp form button {
      max-width: 100%; }
    body.resp form select {
      vertical-align: top;
      font-size: 18pt; }
    body.resp form input[type=text], body.resp form input[type=email], body.resp form input[type=password], body.resp form input[type=number] {
      vertical-align: top;
      font-size: 18pt;
      border: 1px solid #c5c5c5;
      width: 90%; }
    body.resp form textarea {
      width: 90%;
      vertical-align: top;
      border: 1px solid #c5c5c5; }
    body.resp form button, body.resp form input[type=button] {
      font-size: 160%; }
    body.resp form input[type=submit] {
      font-size: 160%; }
    body.resp form input[type=submit].tiny {
      font-size: 120%;
      vertical-align: top; }
    body.resp form.simple_form div.input input[type=text], body.resp form.simple_form div.input input[type=email], body.resp form.simple_form div.input input[type=password], body.resp form.simple_form div.input input[type=number] {
      width: 90%;
      max-width: 90%; }
    body.resp form.simple_form div.input textarea {
      width: 90%;
      max-width: 90%; }
    body.resp div#page section#content {
      margin: 0;
      padding: 0; }
    body.resp #comments h2 {
      display: none; }
    body.resp #posts #posts-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly; }
    body.resp article.post-preview {
      float: none;
      margin: 0 0 10px 0;
      text-align: center;
      vertical-align: middle;
      display: inline-block;
      width: 31vw; }
      body.resp article.post-preview a {
        margin: 0 auto; }
      body.resp article.post-preview img {
        max-height: 150px;
        max-width: 31vw; }
      body.resp article.post-preview .desc {
        font-size: 100%; }
    body.resp .user-disable-cropped-false article.post-preview {
      width: 31vw; }
      body.resp .user-disable-cropped-false article.post-preview img {
        height: auto;
        max-height: none;
        max-width: none;
        width: 31vw; }
        body.resp .user-disable-cropped-false article.post-preview img.has-cropped-false {
          object-fit: cover; }
    body.resp div#options {
      margin-top: 10px;
      font-size: 24pt;
      font-weight: bold; }
    body.resp div#tags {
      margin-top: 30px; }
      body.resp div#tags li {
        margin: 10px 0; }
      body.resp div#tags a.search-tag {
        font-size: 24pt;
        font-weight: bold; }
    body.resp #nav {
      font-size: 2em;
      line-height: 2em;
      display: none; }
    body.resp header#top menu.main {
      padding: 5px 10px; }
    body.resp h1 {
      display: block;
      font-size: 2em;
      margin: 1em 0 0 0; }
    body.resp h2 {
      font-size: 0.825em;
      margin: 1em 0 0 0; }
    body.resp header {
      text-align: center;
      line-height: 2em; }
      body.resp header h1 {
        display: inline; }
    body.resp div#page {
      margin: 0; } }

@media screen and (max-width: 660px) {
  body.resp #c-forum-topics #a-show, body.resp #c-forum-posts #a-show {
    padding: 1em 0.5em; }
  body.resp #c-forum-topics .creator, body.resp #c-forum-posts .creator {
    display: none; }
  body.resp #c-forum-topics .created-at, body.resp #c-forum-posts .created-at {
    display: none; } }

@media screen and (max-width: 440px) {
  body.resp input#expand-search {
    display: none; }
  body.resp body {
    width: 100%; }
  body.resp #post-sections {
    font-size: 1em; } }

#tos-notice {
  display: none; }
