button, input, select, textarea {
  border: 0;
  border-radius: 2px;
  padding: 1px 2px;
}

label {
  font-weight: bold;
  white-space: nowrap;
}

textarea {
  display: block;
  font-family: monospace;
  font-size: 1.2em;
}

textarea.no-block {
  display: inline;
}

input[type=button] {
  min-width: 5rem;
}

input[type=submit] {
  min-width: 5rem;
}

input[type=radio] {
  border-radius: 0;
  box-shadow: none;
}

input[type=date] {
  background-color: $form-date-background;
}

input:focus {
  background: $form-focus-background;
  color: $form-focus-color;
}

select:focus {
  background: $form-focus-background;
  color: $form-focus-color;
}

textarea:focus {
  background: $form-focus-background;
  color: $form-focus-color;
}

input:disabled {
  background-color: $form-disabled-background;
}

.simple_form {
  padding: $padding-050;
  border-radius: $border-radius-half;
  @include themable(){
    background-color: themed("color-section");
  }
}


